import { useEffect } from 'react'
import qs from 'query-string'

import { LOCAL_STORAGE_KEYS } from 'utils/constants'
import { getSearchParams } from 'utils/getQueryParams'

import { useValidInvitationCodeByUserType } from './apollo/hooks'

import Loading from 'components/Loading'

interface Params {
  accessToken: string;
  iduser: string;
  refreshToken: string;
  urlRedirect: string;
  invitationCode?: string;
  userType?: string;
  scope?: string;
}

export default () => {
  const [ validInvitationCodeByUserType ] = useValidInvitationCodeByUserType()

  useEffect(() => {
    const main = async () => {
      const params = getSearchParams<Params>()

      try {
        const totalErrors = 0
        const lsNumberLogins = localStorage.getItem(LOCAL_STORAGE_KEYS.NUMBER_LOGINS)
        const numberLoginsTotal = lsNumberLogins ? Number(lsNumberLogins) + 1 : 1

        localStorage.setItem(LOCAL_STORAGE_KEYS.ACCESS_TOKEN, params.accessToken)
        localStorage.setItem(LOCAL_STORAGE_KEYS.USER_ID, params.iduser)
        localStorage.setItem(LOCAL_STORAGE_KEYS.REFRESH_TOKEN, params.refreshToken)

        localStorage.setItem(LOCAL_STORAGE_KEYS.START_SESSION_DATE, String(new Date().getTime()))
        localStorage.setItem(LOCAL_STORAGE_KEYS.TOTAL_ERRORS, String(totalErrors))
        localStorage.setItem(LOCAL_STORAGE_KEYS.NUMBER_LOGINS, String(numberLoginsTotal))

        localStorage.removeItem(LOCAL_STORAGE_KEYS.SEND_PROFILE_COUNTER)

        const redirectParams = qs.parseUrl(params.urlRedirect)?.query

        const candidateId = redirectParams?.trackingCandidateId

        const candidateAccessTokenKey = `${LOCAL_STORAGE_KEYS.CANDIDATE_ACCESS_TOKEN}-${candidateId}`

        if(params.invitationCode && params.scope && params.userType && candidateId) {
          const response = await validInvitationCodeByUserType({
            variables: {
              invitationCode: params.invitationCode,
              scope         : params.scope,
              userType      : params.userType
            }
          })
          localStorage.setItem(candidateAccessTokenKey, response.data?.validInvitationCodeByUserType ?? '')
        } else {
          localStorage.removeItem(candidateAccessTokenKey)
        }
        window.location.href = params.urlRedirect
      } catch (error) {
        // eslint-disable-next-line no-restricted-syntax
        console.log('error', error.message)
        window.location.href = params.urlRedirect
      }
    }
    main()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Loading />
  )
}
