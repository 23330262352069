import { QueryHookOptions, useMutation, useQuery, MutationHookOptions, useApolloClient } from '@apollo/client'
import { loader } from 'graphql.macro'
import {
  GetCandidateTaskByTaskAndUser_LobbyQuery,
  GetCandidateTaskByTaskAndUser_LobbyQueryVariables,
  VerifyKey_LobbyQuery,
  VerifyKey_LobbyQueryVariables,
  GetCandidateTasksByTask_LobbyQuery,
  GetCandidateTasksByTask_LobbyQueryVariables,
  AssignTask_LobbyMutation,
  AssignTask_LobbyMutationVariables
} from '__generated__/typescript-operations'

const GET_CANDIDATE_TASK_BY_TASK_AND_USER = loader('./getCandidateTaskByTaskAndUser.graphql')
const VERIFY_KEY = loader('./verifyKey.graphql')
const GET_CANDIDATE_TASKS_BY_TASK = loader('./getCandidateTasksByTask.graphql')
const ASSIGN_TASK = loader('./assignTask.graphql')

export const useGetCandidateTaskByTaskAndUser = (
  params?: QueryHookOptions<GetCandidateTaskByTaskAndUser_LobbyQuery, GetCandidateTaskByTaskAndUser_LobbyQueryVariables>
) =>
  useQuery<GetCandidateTaskByTaskAndUser_LobbyQuery, GetCandidateTaskByTaskAndUser_LobbyQueryVariables>(GET_CANDIDATE_TASK_BY_TASK_AND_USER, params)

export const useClientVerifyKey = () => {
  const client = useApolloClient()

  const query = async (variables: VerifyKey_LobbyQueryVariables) => {
    try {
      const {
        data: { verifyKey }
      } = await client.query<VerifyKey_LobbyQuery, VerifyKey_LobbyQueryVariables>({
        query: VERIFY_KEY,
        variables
      })

      return verifyKey
    } catch (error) {
      throw error
    }
  }

  return [ query ]
}

export const useGetCandidateTasksByTask = (
  params?: QueryHookOptions<GetCandidateTasksByTask_LobbyQuery, GetCandidateTasksByTask_LobbyQueryVariables>
) =>
  useQuery<GetCandidateTasksByTask_LobbyQuery, GetCandidateTasksByTask_LobbyQueryVariables>(GET_CANDIDATE_TASKS_BY_TASK, params)

export const useAssignTask = (
  params?: MutationHookOptions<AssignTask_LobbyMutation, AssignTask_LobbyMutationVariables>
) =>
  useMutation<AssignTask_LobbyMutation, AssignTask_LobbyMutationVariables>(
    ASSIGN_TASK,
    params
  )
