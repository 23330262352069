const Query = {
  getBranchOfficesByJobId: {
    keyArgs: [ 'jobId', 'limit', 'search', 'profileId' ],
    merge(existing, incoming, { args }) {
      const { page = 1, limit = 15 } = args
      const offset = (page - 1) * limit
      const merged = existing ? existing.docs.slice(0) : []
      for (let i = 0; i <= incoming.docs.length; i++) merged[offset + i] = incoming.docs[i]

      return {
        ...incoming,
        docs: merged
          .filter(Boolean)
          .filter((docA, index, self) => self.findIndex((docB) => docB.__ref === docA.__ref) === index)
      }
    }
  }
}

export default Query
