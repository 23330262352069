import { useMemo } from 'react'
import { useLocation } from 'react-router-dom'
import { getSearchParams } from 'utils/getQueryParams'

function useSearchParams<T = any>() {
  const location = useLocation()

  const queryStringParams = useMemo(() => getSearchParams<T>(location), [ location ])

  return queryStringParams
}

export default useSearchParams
