import { FC, useEffect, useMemo, useState, useCallback } from 'react'
import { Card, CardHeader, IconButton, CardContent, Drawer, Grid, makeStyles, useTheme, useMediaQuery, Typography } from '@krowdy/kds-core'
import { Close as CloseIcon } from '@material-ui/icons'
import { CheckingList as CheckingListSvg } from '@krowdy/kds-assets'

import { useLocation } from 'react-router'
import queryString from 'query-string'
import ContentImage from 'components/ContentImage'
import useGetCurrentTasks from 'hooks/useGetCurrentTasks'
import { menuOptionsNotification } from 'operations/land'
import { PaletteColor } from 'utils/theme'

import clsx from 'clsx'
import { motion, AnimatePresence, useAnimation } from 'framer-motion'

interface TaskReminderProps {
  jobId: string;
  publicationIndex: number;
}

const TaskReminder: FC<TaskReminderProps> = ({ jobId, publicationIndex }) => {
  const [ isOpen, setIsOpen ] = useState(false)
  const classes = useStyles()
  const theme = useTheme()
  const isAboveMd = useMediaQuery(theme.breakpoints.up('md'))
  const location = useLocation()
  const modalAnimation = useAnimation()
  const blurAnimation = useAnimation()

  const _handleClose = useCallback(async () => {
    if(!isAboveMd) {
      setIsOpen(false)

      return
    }
    menuOptionsNotification(true)
    await Promise.all([
      modalAnimation.start({
        filter: 'opacity(0)',
        scale : 0.1,
        x     : 'calc(-50vw + 29px)',
        y     : 'calc(-50vh + 29px)'
      }, { duration: 0.5 }),
      blurAnimation.start({
        background: 'transparent'
      }, { duration: 0.5 })
    ])
    setIsOpen(false)
  }, [ blurAnimation, isAboveMd, modalAnimation ])

  const {
    job,
    currentStageId,
    getPendingTaskIndex
  } = useGetCurrentTasks({ jobId, publicationIndex: Number(publicationIndex) })

  const pendingStageIndexes = useMemo(() => {
    const stageIndex = job.stages.findIndex((stage) => stage._id === currentStageId)

    return job.stages.reduce((acc, stage, index) => {
      if(index >= stageIndex) return acc

      const pendingTaskIndex = getPendingTaskIndex(stage.tasks)

      if(pendingTaskIndex === -1) return acc

      return acc.concat({
        index,
        stageId: stage._id
      })
    }, [] as any[])
  }
  , [ currentStageId, getPendingTaskIndex, job.stages ])

  const lastPendingTask = useMemo(() => pendingStageIndexes[pendingStageIndexes.length - 1], [ pendingStageIndexes ])

  useEffect(() => {
    const { taskReminderShowed } = queryString.parse(location.search)
    if(!taskReminderShowed)
      if(pendingStageIndexes.length) setIsOpen(true)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const Content = useMemo(() => (
    <Card className={classes.paperRoot}>
      <CardHeader
        action={(
          <IconButton onClick={_handleClose}>
            <CloseIcon fontSize='small' />
          </IconButton>
        )}
        className={classes.cardHeader} />
      <CardContent className={clsx(classes.height100, classes.cardContent)}>
        <ContentImage
          className={classes.contentImage}
          content={
            <Typography className={classes.contentImageContent} variant='body3'>
              Completa las tareas en la <strong>Etapa {lastPendingTask?.index + 1}</strong> para
              estar al día en tu postulación.
              Puedes revisar el estado de tus etapas haciendo click en el Menú.
            </Typography>
          }
          Image={CheckingListSvg}
          // title={!candidateTask.task.contentTask?.content?.htmlContent ? 'Instrucciones' : undefined}
          title='Tienes tareas pendientes en etapas anteriores ' />
      </CardContent>
    </Card>
  ), [
    classes.cardContent, classes.cardHeader, classes.contentImage,
    classes.contentImageContent, classes.height100, classes.paperRoot,
    lastPendingTask?.index, _handleClose
  ])

  if(isAboveMd)
    return (
      <AnimatePresence
        exitBeforeEnter={true} initial={false}>
        {isOpen && (
          <motion.div
            animate={blurAnimation}
            className={classes.backdrop}
            onClick={_handleClose} >
            <motion.div
              animate={modalAnimation}
              className={classes.modal}
              onClick={(e) => e.stopPropagation()}>
              {Content}
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>
    )

  return (
    <Drawer
      anchor='bottom'
      classes={{
        paperAnchorBottom: classes.paper
      }}
      onClose={_handleClose}
      open={isOpen}>
      <div className={classes.container}>
        <Grid
          className={classes.gridContainer}
          container
          justifyContent='center'>
          <Grid
            item md={6}
            xs={12}>
            {Content}
          </Grid>
        </Grid>
      </div>
    </Drawer>
  )
}

const useStyles = makeStyles((theme) => ({
  backdrop: {
    alignItems    : 'center',
    background    : (theme.palette.grey as PaletteColor)['A14'],
    display       : 'flex',
    height        : '100%',
    justifyContent: 'center',
    left          : 0,
    position      : 'absolute',
    top           : 0,
    width         : '100%',
    zIndex        : theme.zIndex.modal
  },
  cardContent: {
    padding: 0
  },
  cardHeader: {
    [theme.breakpoints.up('md')]: {
      paddingBottom: 0
    }
  },
  container: {
    height                      : 'calc(var(--vh, 1vh) * 100 - 163px)',
    margin                      : '0 auto',
    overflow                    : 'hidden',
    [theme.breakpoints.up('md')]: {
      width: 'calc(100% - 84 * 2px)'
    }
  },
  contentImage: {
    paddingBottom               : 120,
    [theme.breakpoints.up('md')]: {
      paddingLeft : 108,
      paddingRight: 108
    }
  },
  contentImageContent: {
    maxWidth: 380
  },
  gridContainer: {
    height: '100%',
    margin: 0
  },
  height100: {
    height: '100%'
  },
  modal: {
    alignItems   : 'center',
    borderRadius : theme.shape.borderRadius * 3,
    display      : 'flex',
    flexDirection: 'column'
  },
  paper: {
    backgroundColor: 'transparent',
    boxShadow      : 'none'
  },
  paperRoot: {
    borderRadius: theme.shape.borderRadius * 2,
    height      : '100%'
  }
}), { name: 'TaskReminder' })

export default TaskReminder
