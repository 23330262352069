import { loader } from 'graphql.macro'
import { useMutation } from '@apollo/client'
import { ApplyEditPostulationMutation, ApplyEditPostulationMutationVariables } from '__generated__/typescript-operations'
import { useEffect } from 'react'

export const EDIT_POSTULATION = loader('./apollo/applyEditPostulation.graphql')

interface UseEditPostulationArgs {
  jobId: string;
  candidateId: string;
  sourceApply?: string;
  slug?: string;
}

export const useEditPostulation = ({ candidateId, jobId, sourceApply, slug }: UseEditPostulationArgs) => {
  const [ editPostulation, editPostulationResult ] = useMutation
    <ApplyEditPostulationMutation, ApplyEditPostulationMutationVariables>(EDIT_POSTULATION, {
      onError: () => {

      }
    })

  useEffect(() => {
    editPostulation({
      variables: {
        candidateId,
        jobId,
        slug,
        sourceApply
      }
    })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return editPostulationResult
}
