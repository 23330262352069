import { useEffect, Suspense, lazy } from 'react'
import { Route, Switch, BrowserRouter as Router } from 'react-router-dom'

import ViewError from 'components/ViewError'
import Unavailable from 'components/Unavailable'
import StorageErrorPage from 'components/StorageErrorPage'
import Loading from 'components/Loading'
import CallbackContainer from 'pages/_callback'
import PrivateRoute from './PrivateRouter'
import { lazyRetry } from 'utils/errorBoundaryService'
import LobbyPage from 'pages/_lobby'
import { getIp } from 'utils/ipify'
import { setIp } from 'operations/app'

import PrivateQueryParamsRoute from './PrivateQueryParamsRoute'
import PrivateRouteMerge from './PrivateRouteMerge'
import EditPostulationRoute from './EditPostulation'

const LandPage = lazy(() => lazyRetry(() => import(/* webpackChunkName: "LandPage" */ 'pages/_land'), 'LandPage'))
const UploadCV = lazy(() => lazyRetry(() => import(/* webpackChunkName: "UploadCV" */ 'pages/_uploadCV'), 'UploadCV'))
const InternalApps = lazy(() => lazyRetry(() => import(/* webpackChunkName: "InternalApps" */ 'pages/_internalapps'), 'InternalApps'))
const VideInterview = lazy(() => lazyRetry(() => import(/* webpackChunkName: "VideInterview" */ 'pages/_scheduled'), 'VideInterview'))
const Merge = lazy(() => lazyRetry(() => import(/* webpackChunkName: "Merge" */ 'pages/_merge'), 'Merge'))
const MergeValidatePage = lazy(() => lazyRetry(() => import(/* webpackChunkName: "MergeValidatePage" */ 'pages/_merge/_validate'), 'MergeValidatePage'))
const PublicationPage = lazy(() => lazyRetry(() => import(/* webpackChunkName: "PublicationPage" */ 'pages/_publication'), 'PublicationPage'))
const ProfilePage = lazy(() => lazyRetry(() => import(/* webpackChunkName: "ProfilePage" */ 'pages/_myprofile'), 'ProfilePage'))
const ResetTask = lazy(() => lazyRetry(() => import(/* webpackChunkName: "ResetTask" */ 'pages/_resetTask'), 'ResetTask'))
const ReferentsPage = lazy(() => lazyRetry(() => import(/* webpackChunkName: "ReferentsPage" */ 'pages/_referents'), 'ReferentsPage'))
const MassivePage = lazy(() => lazyRetry(() => import(/* webpackChunkName: "MassivePage" */ 'pages/_massive'), 'MassivePage'))

const Root = () =>  {
  useEffect(() => {
    const main = async () => {
      let vh = window.innerHeight * 0.01
      // Then we set the value in the --vh custom property to the root of the document
      document.documentElement.style.setProperty('--vh', `${vh}px`)

      const ip = await getIp().catch(() => '')

      if(ip) setIp(ip)
    }

    main()
  }, [])

  return (
    <Router>
      <Switch>
        <Route path='/forbidden' render={() => <ViewError />} />

        <Route path='/unavailable' render={() => <Unavailable />} />

        <Route path='/storage' render={() => <StorageErrorPage />} />

        <Route component={CallbackContainer} exact path='/callback' />

        <Route
          exact
          path={[
            '/job/:jobId/publication/:publicationIndex/candidate/:candidateId',
            '/job/:jobId/publication/:publicationIndex/candidate/:candidateId/task/:taskId'
          ]}
          render={() => (
            <EditPostulationRoute>
              <Suspense fallback={<Loading />}>
                <LandPage />
              </Suspense>
            </EditPostulationRoute>
          )}
          strict />

        <Route
          exact
          path={[ '/referents/:jobId/publication/:publicationIndex' ]}
          render={() => (
            <PrivateRoute>
              <Suspense fallback={<Loading />}>
                <ReferentsPage />
              </Suspense>
            </PrivateRoute>
          )}
          strict />

        <Route
          exact
          path={[ '/massive/:jobId/publication/:publicationIndex' ]}
          render={() => (
            <Suspense fallback={<Loading />}>
              <MassivePage />
            </Suspense>
          )}
          strict />

        <Route
          exact
          path='/publication/:jobId/publication/:publicationIndex'
          render={() => (
            <PrivateQueryParamsRoute>
              <Suspense fallback={<Loading />}>
                <PublicationPage />
              </Suspense>
            </PrivateQueryParamsRoute>
          )}
          strict />

        <Route
          exact
          path='/resettask/:jobId/:candidateTaskId'
          render={() => (
            <PrivateRoute>
              <Suspense fallback={<Loading />}>
                <ResetTask />
              </Suspense>
            </PrivateRoute>
          )}
          strict />

        <Route
          exact
          path={[ '/myprofile' ]}
          render={() => (
            <PrivateRoute>
              <Suspense fallback={<Loading />}>
                <ProfilePage />
              </Suspense>
            </PrivateRoute>
          )}
          strict />

        <Route
          exact
          path={[ '/profile' ]}
          render={() => (
            <PrivateRoute>
              <Suspense fallback={<Loading />}>
                <ProfilePage />
              </Suspense>
            </PrivateRoute>
          )}
          strict />

        <Route
          exact
          path={[
            '/job/:jobId/publication/:publicationIndex'
          ]}
          render={() => (
            <PrivateRoute>
              <Suspense fallback={<Loading />}>
                <LandPage />
              </Suspense>
            </PrivateRoute>
          )}
          strict />

        <Route
          exact
          path={[ '/job/:jobId/publication/:publicationIndex/upload-cv' ]}
          render={() => (
            <PrivateRoute>
              <Suspense fallback={<Loading />}>
                <UploadCV />
              </Suspense>
            </PrivateRoute>
          )}
          strict />

        <Route
          exact
          path='/internalapps/:codeTask/:candidateTaskId/:jobId'
          render={() => (
            <PrivateRoute>
              <Suspense fallback={<Loading />}>
                <InternalApps />
              </Suspense>
            </PrivateRoute>
          )} />

        <Route
          exact
          path={[ '/scheduled/:jobId/task/:taskId/publication/:publicationIndex' ]}
          render={() => (
            <PrivateRoute>
              <Suspense fallback={<Loading />}>
                <VideInterview />
              </Suspense>
            </PrivateRoute>
          )}
          strict />

        <Route
          exact
          path={[ '/lobby/:jobId/publication/:publicationIndex/:taskId' ]}
          render={() => (
            <PrivateRoute>
              <Suspense fallback={<Loading />}>
                <LobbyPage />
              </Suspense>
            </PrivateRoute>
          )}
          strict />

        <Route
          exact
          path={[ '/merge/:jobId/publication/:publicationIndex' ]}
          render={(props) => (
            <PrivateRouteMerge {...props}>
              <Suspense fallback={<Loading />}>
                <Merge />
              </Suspense>
            </PrivateRouteMerge>
          )}
          strict />

        <Route
          exact
          path={[ '/merge/validate' ]}
          render={() => (
            <PrivateRoute>
              <Suspense fallback={<Loading />}>
                <MergeValidatePage />
              </Suspense>
            </PrivateRoute>
          )}
          strict />
        {/* <Route
          component={InternalApps}
          exact
          path='/internalapps/:codeTask/:resultTaskId/:jobId' /> */}
        {/* <Redirect
            exact
            from='/'
            strict
            to='/no-found' /> */}
        <Route render={() => <ViewError />} />
      </Switch>
    </Router>
  )
}

export default Root
