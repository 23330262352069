import { useQuery, QueryHookOptions } from '@apollo/client'
import { loader } from 'graphql.macro'
import queryString from 'query-string'
import { matchPath } from 'react-router'

import { VerifyUserQuery, VerifyUserQueryVariables } from '__generated__/typescript-operations'

import { LOCAL_STORAGE_KEYS } from 'utils/constants'
import { mixPanelService } from 'utils/track'
import { verifyAllowPublicAccess } from 'utils/flows'

const {
  REACT_APP_ACCOUNTS_FRONT_URL
} = process.env

export const VERIFY_USER = loader('./apollo/verifyUser.graphql')

export const useLoginRequired = (args?: QueryHookOptions<VerifyUserQuery, VerifyUserQueryVariables>) =>
  useQuery<VerifyUserQuery, VerifyUserQueryVariables>(VERIFY_USER, {
    ...args,
    onCompleted: ({ getUser: user }) => {
      mixPanelService.validateSessionSync(user)
      window.localStorage.setItem(LOCAL_STORAGE_KEYS.USER_ID, String(user?._id))
      window.localStorage.setItem(LOCAL_STORAGE_KEYS.EMAIL, String(user?.email))
      window.localStorage.setItem(LOCAL_STORAGE_KEYS.FIRSTNAME, String(user?.firstName))
      window.localStorage.setItem(LOCAL_STORAGE_KEYS.LASTNAME, String(user?.lastName))
    },
    onError: () => {
      if(!verifyAllowPublicAccess()) {
        window.localStorage.removeItem(LOCAL_STORAGE_KEYS.MIXPANEL_SESSION)

        const match = matchPath<{jobId: string; publicationIndex: string;}>(window.location.pathname, {
          exact: true,
          path : '/:type/:jobId/publication/:publicationIndex'
        })

        const url = `${REACT_APP_ACCOUNTS_FRONT_URL}/login?${queryString.stringify({
          jobId      : match?.params?.jobId,
          urlCallback: `${window.location.origin}/callback`,
          urlRedirect: window.location.href
        })}`
        window.location.href = url
      }
    }
  })

