import { keyBy } from '@krowdy/utils'
import { Maybe } from 'graphql/jsutils/Maybe'

import { ActionRequestStatus, CandidateStatus, CandidateTask } from '__generated__/typescript-operations'
import { Task } from 'interfaces/pages/_land/types'
import { QueryStringParams } from 'interfaces/pages/_land'

import { filterAndSortTasksByCandidateTasks } from 'utils/sortCandidateTasks'
import { incompleteStatuses } from 'utils/constants'
import { getSearchParams } from 'utils/getQueryParams'
import { validEditPostulation } from 'utils/flows'

import { useGetJobStageFragment } from './../layouts/Main/apollo/hooks'
import { useGetCandidateFragment } from 'layouts/Main/apollo/hooks'

interface FilterAndSortTasksByCandidateTasksArgs {
  allTasks: Task[];
  candidateTasks: Pick<CandidateTask, '_id' | 'applyInstanceStatus' | 'taskId'>[];
  isScheduleTasksVisible?: Maybe<boolean>;
  filterTaskIds?: Maybe<string[]>;
  currentTaskId?: Maybe<string>;
}

interface UseGetNextPostulationStateProps {
  jobId: string;
  candidateId: string;
}

interface UseGetNextPostulationStateQuery {
  isScheduleTasksVisible?: boolean;
  currentTaskId?: Maybe<string>;
  firstLoad?: boolean;
}

const useGetNextPostulationState = ({ jobId, candidateId }: UseGetNextPostulationStateProps) => {
  const [ getCandidateFragment ] = useGetCandidateFragment()
  const [ getJobStageFragment ] = useGetJobStageFragment()

  const getNextPendingTaskByCandidateTasks = (
    args: FilterAndSortTasksByCandidateTasksArgs
  ): Maybe<Task> => {
    const tasks = filterAndSortTasksByCandidateTasks({
      ...args,
      filterBlockedToEdit: true
    })

    if(validEditPostulation()) {
      const taskIndex = tasks.findIndex((task) => task._id === args.currentTaskId)
      const nextTask = taskIndex >= 0 ? tasks[taskIndex + 1] : null

      if(nextTask) return nextTask

      const lastTask = Array.from(tasks).pop()

      return lastTask
    }

    const candidateTaskByTask = keyBy(args.candidateTasks, 'taskId')

    return tasks
      .find((task) => {
        const candidateTask = candidateTaskByTask[task._id]

        if(!candidateTask) return false

        return incompleteStatuses.includes(candidateTask.applyInstanceStatus)
      })
  }

  const query = ({ isScheduleTasksVisible, currentTaskId, firstLoad }: UseGetNextPostulationStateQuery = {}) => {
    const qsParams = getSearchParams<QueryStringParams>()

    const jobCache = getJobStageFragment(jobId)

    const candidateCache = getCandidateFragment(candidateId)

    if(!jobCache) throw new Error('job not found in cache')
    if(!candidateCache) throw new Error('candidate not found in cache')

    const { stages } = jobCache

    const [ firstStage ] = stages
    const [ firstTask ] = firstStage.tasks
    const { candidateTasks } = candidateCache

    const stageBy = keyBy(stages, '_id')

    const currentStageIndex = stages.findIndex((stage) => stage._id === candidateCache?.stageId)

    const candidateTaskByTask = keyBy(candidateTasks, 'taskId')

    const currentStage = stages[currentStageIndex]

    const allTasks = stages.flatMap(({ tasks }) => tasks)

    if((
      candidateCache.applyInstanceStatus === CandidateStatus.Apply && (firstStage._id === candidateCache?.stageId || !currentStage)
    ) || (
      firstLoad && candidateCache.lastActionRequests?.some(({ status }) => status === ActionRequestStatus.Pending)
    ))
      return {
        currentCandidateTask: null,
        currentStageId      : firstStage._id,
        currentTask         : firstTask,
        currentTaskId       : firstTask._id,
        currentTaskIndex    : 0,
        message             : 'candidato entro a tarea perfil'
      }

    const firstPendingTaskByCurrentStage = getNextPendingTaskByCandidateTasks({
      allTasks,
      candidateTasks: candidateTasks.filter(({ stageId }) => String(stageId) === String(candidateCache?.stageId)),
      currentTaskId,
      filterTaskIds : qsParams.taskIds,
      isScheduleTasksVisible
    })

    if(firstPendingTaskByCurrentStage) {
      const tasks = stageBy[firstPendingTaskByCurrentStage.stageId!]?.tasks ?? []

      return {
        currentCandidateTask: candidateTaskByTask[firstPendingTaskByCurrentStage._id],
        currentStageId      : firstPendingTaskByCurrentStage.stageId,
        currentTask         : firstPendingTaskByCurrentStage,
        currentTaskId       : firstPendingTaskByCurrentStage._id,
        currentTaskIndex    : tasks.findIndex(({ _id }) => _id === firstPendingTaskByCurrentStage._id),
        message             : 'candidato tiene pendientes en su etapa actual'
      }
    }

    const otherStageIds = stages.slice(0, currentStageIndex).map(({ _id }) => _id)

    const firstPendingTask = getNextPendingTaskByCandidateTasks({
      allTasks,
      candidateTasks: candidateTasks.filter(({ stageId }) => otherStageIds.includes(String(stageId))),
      currentTaskId,
      filterTaskIds : qsParams.taskIds,
      isScheduleTasksVisible
    })

    const tasks = stageBy[firstPendingTask?.stageId!]?.tasks ?? []

    return {
      currentCandidateTask: firstPendingTask ? candidateTaskByTask[firstPendingTask._id] : null,
      currentStageId      : firstPendingTask?.stageId ?? candidateCache?.stageId,
      currentTask         : firstPendingTask,
      currentTaskId       : firstPendingTask?._id,
      currentTaskIndex    : tasks.findIndex(({ _id }) => _id === firstPendingTask?._id),
      message             : 'candidato tiene pendientes en etapas anteriores o posiblemente finalizo'
    }
  }

  return [ query ]
}

export default useGetNextPostulationState
