import { useLayoutEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Params } from 'interfaces/pages/_land'
import State from './apollo/reactiveVars'
import { useGetJob } from 'layouts/Main/apollo/hooks'
import useGetNextPostulationState from './useGetNextPostulationState'
import { useReactiveVar } from '@apollo/client'

interface UseInitPostulationStateArgs {
  jobId: string;
  publicationIndex?: number;
  candidateId: string;
}

const useInitPostulationState = ({ jobId, publicationIndex = 0, candidateId }: UseInitPostulationStateArgs) => {
  const params = useParams<Params>()

  const initializePostulantState = useReactiveVar(State.initializePostulantStateVar)

  const [ loading, setLoading ] = useState(!initializePostulantState)

  const { job } = useGetJob({
    variables: {
      jobId,
      publicationIndex: Number(publicationIndex)
    }
  }).data! || { job: { similarOnets: [], stages: [] } }

  const [ nextPostulationState ] = useGetNextPostulationState({
    candidateId,
    jobId
  })

  useLayoutEffect(() => {
    try {
      const initializePostulantState = State.initializePostulantStateVar()
      if(initializePostulantState) return

      if(params.taskId) {
        const stage = job.stages.find((stage) => stage.tasks.find(({ _id }) => _id === params.taskId))

        State.currentStageIdVar(stage?._id)
        State.currentTaskIdVar(params.taskId)
        State.initializePostulantStateVar(true)

        return
      }

      const { currentStageId, currentTaskId } = nextPostulationState({ firstLoad: true, isScheduleTasksVisible: true })

      State.currentStageIdVar(currentStageId)
      State.currentTaskIdVar(currentTaskId)
      State.initializePostulantStateVar(true)
    } catch (error) {
      // eslint-disable-next-line no-restricted-syntax
      console.log('error', error)
    } finally {
      setLoading(false)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return {
    loading
  }
}

export default useInitPostulationState
