import { useCallback, useMemo } from 'react'
import { useReactiveVar } from '@apollo/client'

import { CandidateStatus } from '__generated__/typescript-operations'

import { keyBy } from 'utils/by'
import { filterAndSortTasksByCandidateTasks } from 'utils/sortCandidateTasks'
import { incompleteStatuses, PathContentForm } from 'utils/constants'
import { isCandidatePublicView } from 'utils/flows'

import { QueryStringParams } from 'interfaces/pages/_land'
import { Task, Stage } from 'interfaces/pages/_land/types'

import State from './apollo/reactiveVars'

import { useGetCandidate, useGetJob } from 'layouts/Main/apollo/hooks'
import useInitPostulationState from './useInitPostulationState'
import useSearchParams from './useSearchParams'

interface UseGetCurrentTasksArgs {
  jobId: string;
  publicationIndex?: number;
}

const useGetCurrentTasks = ({ jobId, publicationIndex = 0 }: UseGetCurrentTasksArgs) => {
  const qsParams = useSearchParams<QueryStringParams>()

  const { getCandidate: candidate } = useGetCandidate({
    jobId,
    slug               : qsParams.slug,
    sourceApply        : qsParams?.utm_source,
    trackingCandidateId: qsParams.trackingCandidateId
  }).data! || { getCandidate: { candidateTasks: [] } }

  const { job } = useGetJob({
    variables: {
      jobId,
      publicationIndex: Number(publicationIndex)
    }
  }).data! || { job: { similarOnets: [], stages: [] } }

  const { loading: postulationStateLoading } = useInitPostulationState({
    candidateId: candidate._id,
    jobId,
    publicationIndex
  })

  const isOutOfProcess = candidate.status === CandidateStatus.Rejected

  const isCongratulationView = useReactiveVar(State.isCongratulationViewVar)
  const currentStageId = useReactiveVar(State.currentStageIdVar)
  const currentTaskId = useReactiveVar(State.currentTaskIdVar)

  // las tareas q vera el candidato según su estado y la etapa en la q se encuentra o en la se movió
  const currentTasks = useMemo(() => {
    const stage = job.stages.find(({ _id }) => _id === currentStageId)

    const tasks = filterAndSortTasksByCandidateTasks({
      allTasks              : stage?.tasks ?? [],
      candidateTasks        : candidate.candidateTasks,
      filterTaskIds         : qsParams.taskIds,
      isScheduleTasksVisible: true
    })

    return tasks
  }, [ candidate.candidateTasks, currentStageId, job.stages, qsParams.taskIds ])

  const isApplicantCandidateAndInAttractionStage = useMemo(() => {
    const [ firstStage ] = job.stages
    const currentStage = job.stages.find(({ _id }) => _id === candidate.stageId)
    const candidateInApplyStage = firstStage._id === candidate.stageId || !currentStage

    return candidate.applyInstanceStatus === CandidateStatus.Apply && candidateInApplyStage
  }, [ candidate.applyInstanceStatus, candidate.stageId, job.stages ])

  const currentVisibleTasks = useMemo(() => {
    const stage = job.stages.find(({ _id }) => _id === currentStageId)

    const tasks = filterAndSortTasksByCandidateTasks({
      allTasks              : stage?.tasks ?? [],
      candidateTasks        : isApplicantCandidateAndInAttractionStage ? null : candidate.candidateTasks,
      filterTaskIds         : qsParams.taskIds,
      isScheduleTasksVisible: isCandidatePublicView()
    })

    return tasks
  }, [ candidate.candidateTasks, isApplicantCandidateAndInAttractionStage, currentStageId, job.stages, qsParams.taskIds ])

  const allCurrentTasks = useMemo(() => {
    const currentStage = job.stages.find(({ _id }) => _id === currentStageId)
    let allTasks = currentStage?.tasks || []

    if(candidate.applyInstanceStatus === CandidateStatus.Apply || !currentStage) {
      const [ firstStage ] = job.stages

      allTasks = firstStage ? firstStage.tasks : []
    }

    return allTasks
  }, [ candidate.applyInstanceStatus, currentStageId, job.stages ])

  const currentTaskIndex = useMemo(() => currentVisibleTasks.findIndex((task) => task._id === currentTaskId)
    , [ currentTaskId, currentVisibleTasks ])

  const candidateTaskByTask = useMemo(() => keyBy(candidate.candidateTasks, 'taskId')
    , [ candidate.candidateTasks ])

  const currentCandidateTask = useMemo(() =>
    candidateTaskByTask[currentTaskId!]
  , [ candidateTaskByTask, currentTaskId ])

  const currentTask = useMemo(() =>
    currentTasks[currentTaskIndex]
  , [ currentTasks, currentTaskIndex ])

  const getStageIndex = useCallback((stageId: string) => {
    const stageIndex = job.stages.findIndex((stage) => stage._id === stageId)
    const stage = job.stages[stageIndex]

    if(!stage) return 0

    return stageIndex
  }, [ job.stages ])

  const candidateStageIndex = useMemo(() => getStageIndex(candidate.stageId!), [ candidate.stageId, getStageIndex ])

  const currentStageIndex = useMemo(() => getStageIndex(currentStageId!), [ currentStageId, getStageIndex ])

  const getPendingTaskIndex = useCallback((tasks: Task[], taskId?: string) =>
    tasks.findIndex((task) => {
      if(taskId && task._id === currentTaskId) return false

      if(task.categoryTask.pathContentForm === PathContentForm.Curriculum)
        return candidate.applyInstanceStatus === CandidateStatus.Apply

      const candidateTask = candidateTaskByTask[task._id]

      return incompleteStatuses.includes(candidateTask?.applyInstanceStatus)
    })
  , [ candidate.applyInstanceStatus, candidateTaskByTask, currentTaskId ])

  const pendingTaskIndex = useMemo(() =>
    getPendingTaskIndex(currentTasks, currentTaskId!)
  , [ currentTaskId, currentTasks, getPendingTaskIndex ])

  /* todas las etapas o etapas menores a la indicada */
  const getPendingStageIndex = useCallback((stages: Stage[], stageId?: string) => {
    const stageIndex = stages.findIndex((stage) => stage._id === stageId)

    return stages.findIndex((stage, index) => {
      if(stageId && index <= stageIndex) return false

      return getPendingTaskIndex(stage.tasks) !== -1
    })
  }
  , [ getPendingTaskIndex ])

  /*
    etapa maxima pendiente del candidato
  */
  const pendingStageIndex = useMemo(() =>
    getPendingStageIndex(job.stages.slice(0, Math.max(candidateStageIndex + 1, 1)), currentStageId!)
  , [ candidateStageIndex, currentStageId, getPendingStageIndex, job.stages ])

  return {
    allCurrentTasks,
    candidate,
    candidateTaskByTask,
    currentCandidateTask,
    currentStageId,
    currentStageIndex,
    currentTask,
    currentTaskId,
    currentTaskIndex,
    currentTasks,
    currentVisibleTasks,
    getPendingTaskIndex,
    isApplicantCandidateAndInAttractionStage,
    isCongratulationView,
    isOutOfProcess,
    job,
    pendingStageIndex,
    pendingTaskIndex,
    postulationStateLoading
  }
}

export default useGetCurrentTasks
