import React, { FC, useMemo, useState } from 'react'
import clsx from 'clsx'
import dayjs from 'dayjs'

import { Theme } from 'utils/theme'
import { GetCandidateTasksByTask_LobbyQuery, Maybe } from '__generated__/typescript-operations'
import { isSameDayAndMonth } from 'utils/dates'

import { useCreateResultTask } from 'pages/_land/apollo/hooks'
import { useAssignTask, useGetCandidateTasksByTask } from '../apollo/hooks'

import ScheduleCard from './ScheduleCard'
import { Button, CircularProgress, List, makeStyles, Typography } from '@krowdy/kds-core'

interface CandidatesLobbyProps {
  taskId: string;
  jobId: string;
}

type Candidate = GetCandidateTasksByTask_LobbyQuery['getCandidateTasksByTask'][0]['candidate']

const CandidatesLobby: FC<CandidatesLobbyProps> = ({ taskId, jobId }) => {
  const classes = useStyles()

  const getCandidateTasksByTaskQuery = useGetCandidateTasksByTask({
    variables: {
      taskId
    }
  })

  const [ createResultTask, createResultTaskResult ] = useCreateResultTask()
  const [ assignTask, assignTaskResult ] = useAssignTask()

  const candidateTasks = useMemo(() =>
    getCandidateTasksByTaskQuery.data?.getCandidateTasksByTask ?? []
  , [ getCandidateTasksByTaskQuery.data?.getCandidateTasksByTask ])

  const candidatesLobby = useMemo(() => {
    const taskWithCandidateByCandidateTasks: {
      candidates: Record<string, any>;
      taskDate: any;
    }[] = []

    const findTaskWithCandidateByCandidateTask = (candidateTask: any) => {
      if(!candidateTask.taskDate)
        return taskWithCandidateByCandidateTasks.findIndex(({ taskDate }) => !taskDate)

      return taskWithCandidateByCandidateTasks.findIndex(({ taskDate : date }) => isSameDayAndMonth(date, candidateTask.taskDate))
    }

    for (let candidateTask of candidateTasks) {
      const { taskDate = null, _id } = candidateTask
      const findIndex = findTaskWithCandidateByCandidateTask(candidateTask)

      if(findIndex === -1) {
        taskWithCandidateByCandidateTasks.push({ candidates: { [_id]: candidateTask }, taskDate })

        continue
      }

      taskWithCandidateByCandidateTasks[findIndex] = {
        candidates: {
          ...taskWithCandidateByCandidateTasks[findIndex].candidates,
          [_id]: candidateTask
        },
        taskDate
      }
    }

    return taskWithCandidateByCandidateTasks.sort((a, b) =>
      new Date(a.taskDate).getTime() -  new Date(b.taskDate).getTime()
    )
  }, [ candidateTasks ])

  const [ selectedCandidateTaskId, setSelectedCandidateTaskId ] = useState<string | null>(null)
  const [ candidate, setCandidate ] = useState<Maybe<Candidate>>(null)

  const selectCandidate = (candidateTaskId: string) => {
    const candidateTask = candidateTasks.find(({ _id }) => _id === candidateTaskId)

    if(candidateTask) {
      const { candidate } = candidateTask

      setCandidate(selectedCandidateTaskId === candidateTaskId ? null : candidate)
      setSelectedCandidateTaskId((prev) => prev === candidateTaskId ? null : candidateTaskId)
    }
  }

  const _handleResponsableExec = async () => {
    const candidateTask = candidateTasks.find(({ _id }) => _id === selectedCandidateTaskId)

    if(candidateTask) {
      const { resultTaskId } = candidateTask

      if(!resultTaskId)
        await createResultTask({
          variables: {
            candidateTaskId: candidateTask._id,
            jobId,
            taskId
          }
        })

      const assignTaskResult = await assignTask({
        variables: {
          candidateTaskId: selectedCandidateTaskId!
        }
      })

      if(assignTaskResult.data?.assignTask)
        window.location.href = assignTaskResult.data?.assignTask
    }
  }

  const pendingCandidates = candidateTasks.filter(({ status }) => status !== 'executed' && status !== 'reviewed')

  return (
    <>
      <div className={classes.candidatesContainer}>
        {
          getCandidateTasksByTaskQuery.loading ? (
            <div className={classes.loadingContainer}>
              <CircularProgress size={25} />
            </div>
          ) : (
            <>
              <Typography className={clsx(classes.margins, classes.content, { [classes.emptyText]: !candidatesLobby.length })} variant='body3' >
                {
                  candidatesLobby.length ?
                    `Hay ${pendingCandidates.length} candidatos esperando a ser entrevistados.` :
                    'Aún no se encuentra ningún candidato en la sala de espera.'
                }
              </Typography>
              <div className={classes.list}>
                {
                  candidatesLobby.map(({ candidates, taskDate }, index) => {
                    const dayParsed = taskDate ? dayjs(taskDate).format('dd MMM') : 'Sin agendar'

                    return (
                      <List key={`${dayParsed}-${index}`}>
                        <Typography className={classes.dayText}>
                          {dayParsed}
                        </Typography>
                        {
                          Object.values(candidates)
                            .map(({ _id: candidateTaskId, candidate, _id, status }: any) => (
                              <ScheduleCard
                                candidate={candidate}
                                isSelected={selectedCandidateTaskId === candidateTaskId}
                                key={_id}
                                onClick={() => selectCandidate(candidateTaskId)}
                                status={status}
                                taskDate={taskDate} />
                            ))
                        }
                      </List>
                    )
                  })
                }
              </div>
              {candidate ? (
                <div className={classes.redirectButton}>
                  <Button
                    className={classes.margins}
                    color='primary'
                    disabled={getCandidateTasksByTaskQuery.loading || createResultTaskResult.loading || assignTaskResult.loading}
                    onClick={_handleResponsableExec}
                    variant='contained'>
                    {!(getCandidateTasksByTaskQuery.loading || createResultTaskResult.loading || assignTaskResult.loading) ?
                      `Entrevistar a ${candidate.firstName}` :
                      <CircularProgress size={25} />
                    }
                  </Button>
                </div>
              ) : null}
            </>
          )
        }
      </div>
    </>
  )
}

const useStyles = makeStyles<Theme>((theme) => ({
  candidatesContainer: {
    width: '100%'
  },
  content: {
    color: theme.palette.secondary[10]
  },
  dayText: {
    color       : theme.palette.secondary[100],
    marginBottom: theme.spacing(0.5),
    textAlign   : 'left'
  },
  emptyText: {
    textAlign: 'justify'
  },
  list: {
    background  : theme.palette.primary[900],
    borderRadius: theme.spacing(1),
    maxHeight   : 400,
    overflow    : 'auto',
    padding     : theme.spacing(1),
    width       : '100%'
  },
  loadingContainer: {
    display       : 'flex',
    justifyContent: 'center',
    justifyItems  : 'center'
  },
  margins: {
    margin: theme.spacing(2.5, 0)
  },
  redirectButton: {
    display       : 'flex',
    justifyContent: 'center'
  }
}), { name: 'CandidatesLobby' })

export default CandidatesLobby
