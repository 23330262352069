import { MutationHookOptions, QueryHookOptions, useMutation, useQuery } from '@apollo/client'
import { loader } from 'graphql.macro'

const GET_TASK_BY_ID = loader('./apollo/getTask.graphql')
const UPDATE_TASK_DATE = loader('./apollo/updateTaskDate.graphql')
const UPDATE_FIRST_TIME_IN = loader('./apollo/updateFirstTimeIn.graphql')
const RESET_TASK = loader('./apollo/resetTask.graphql')

export const useGetTaskById = (params?: QueryHookOptions) =>
  useQuery(GET_TASK_BY_ID, params)

export const useUpdateTaskDate = (params?: MutationHookOptions) =>
  useMutation(UPDATE_TASK_DATE, params)

export const useUpdateFirstTimeIn = (params?: MutationHookOptions) =>
  useMutation(UPDATE_FIRST_TIME_IN, params)

export const useResetTask = (params?: MutationHookOptions) =>
  useMutation(RESET_TASK, params)
