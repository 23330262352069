import { krowdyTheme, ThemeProvider, createTheme, CssGlobal } from '@krowdy/kds-core'
import { FC, ReactNode, useEffect, useMemo } from 'react'
import { Helmet } from 'react-helmet'
import qs from 'query-string'
import { Redirect, useParams, matchPath, generatePath, useRouteMatch } from 'react-router'
import { MuiPickersUtilsProvider } from '@ghondar/pickers'
import esLocale from 'dayjs/locale/es'
import DayjsUtils from '@date-io/dayjs'

import { getGradientPaletteColor } from 'utils/colors'
import { cleanObject } from 'utils/object'
import { inIframe } from 'utils/iframe'
import { removeTypename } from 'utils/skip'
import { getSearchParams } from 'utils/getQueryParams'

import useSearchParams from 'hooks/useSearchParams'
import { useGetJob, useGetTheme, useLazyGetCandidate } from './apollo/hooks'
import { useLazyGetProfileExhaustive } from 'containers/profile/apollo/hooks'

import Loading from 'components/Loading'
import TaskReminder from './TaskReminder'

const { NODE_ENV } = process.env

export interface Params {
  jobId?: string;
  publicationIndex?: string;
}

export interface QsWrapperParams {
  jobId?: string;
  jobTitle?: string;
  companyName?: string;
  publicationIndex?: string;
  slug?: string;
  q?: string;
  trackingCandidateId?: string;
  utm_source?: string;
  open_job_detail?: string;
  alloweditpostulation?: string;
}

interface ThemeCustomProviderProps {
  preloadCandidate?: boolean;
  preloadJob?: boolean;
  preloadProfile?: boolean;
  children?: ReactNode;
}

const ThemeCustomProvider: FC<ThemeCustomProviderProps> = ({
  children,
  preloadCandidate = true,
  preloadProfile = true
}) => {
  const params = useParams<Params>()
  const match = useRouteMatch()

  const qsParams = useSearchParams<QsWrapperParams>()

  const getThemeQuery = useGetTheme({ jobId: params.jobId ?? qsParams.jobId!, slug: qsParams.slug })

  const [ getCandidateQuery, getCandidateQueryResult ] = useLazyGetCandidate()

  const [ getProfileExhaustiveQuery, getProfileExhaustiveQueryResult ] = useLazyGetProfileExhaustive()

  const getJobQuery = useGetJob({
    onCompleted: ({ job }) => {
      if(job.mergeJobId)
        window.location.href = `${window.location.origin}${generatePath(match.path, {
          ...params,
          jobId: job.mergeJobId
        })}`
    },
    variables: {
      jobId           : params.jobId ?? qsParams.jobId!,
      publicationIndex: Number(params.publicationIndex ?? qsParams.publicationIndex ?? 0)
    }
  })

  const { cssTextFamily, ...theme } = useMemo(() =>
    getThemeQuery.data?.getTheme || { cssTextFamily: '', overrides: {}, palette: { primary: { main: '' }, secondary: { main: '' } } }
  , [ getThemeQuery.data?.getTheme ])

  const loading = useMemo(() =>
    getThemeQuery.loading || getJobQuery.loading || getCandidateQueryResult.loading || getProfileExhaustiveQueryResult.loading
  , [ getCandidateQueryResult.loading, getJobQuery.loading, getProfileExhaustiveQueryResult.loading, getThemeQuery.loading ])

  const error = useMemo(() =>
    getThemeQuery.error || getJobQuery.error || getCandidateQueryResult.error || getProfileExhaustiveQueryResult.error
  , [ getCandidateQueryResult.error, getJobQuery.error, getProfileExhaustiveQueryResult.error, getThemeQuery.error ])

  const themeGenerated = useMemo(() => {
    if((
      (!qsParams.slug && !getJobQuery.data?.job.companyPublished?.premium) ||
      loading ||
      error
    )) return createTheme({
      ...krowdyTheme,
      palette: {
        ...krowdyTheme.palette,
        custom: {
          ...(krowdyTheme.palette as any).krowdy,
          ...getGradientPaletteColor((krowdyTheme.palette as any).krowdy[300]!),
          main: (krowdyTheme.palette as any).krowdy[300]
        }
      }
    } as any)

    const cleanTheme = cleanObject(theme)

    const themeFinal = createTheme({
      ...krowdyTheme,
      palette: {
        ...krowdyTheme.palette,
        ...cleanTheme.palette,
        primary: {
          ...cleanTheme.palette.primary,
          ...getGradientPaletteColor(cleanTheme.palette.primary.main!)
        },
        secondary: {
          ...cleanTheme.palette.secondary,
          ...getGradientPaletteColor(cleanTheme.palette.secondary.main!)
        },
        ...getJobQuery.data?.job.companyPublished?.premium ? ({
          background: {
            'default': '#f2f4f7'
          },
          custom: {
            ...cleanTheme.palette.custom,
            ...getGradientPaletteColor(cleanTheme.palette.custom?.main!)
          }
        }) : ({
          custom: {
            ...cleanTheme.palette.primary,
            ...getGradientPaletteColor(cleanTheme.palette.primary.main!)
          }
        })
      }
    } as any)

    return removeTypename(themeFinal)
  }, [ error, getJobQuery.data?.job.companyPublished?.premium, loading, qsParams.slug, theme ])

  useEffect(() => {
    if(qsParams.slug === 'krowdy') {
      const { origin, pathname } = window.location
      const { slug, ...rest } = getSearchParams()
      // eslint-disable-next-line no-restricted-syntax
      console.log('slug', slug)
      window.location.href = `${origin}${pathname}?${qs.stringify(rest)}`
    }

    if(preloadCandidate)
      getCandidateQuery({
        variables: {
          jobId              : params.jobId ?? qsParams.jobId!,
          slug               : qsParams.slug,
          sourceApply        : qsParams?.utm_source,
          trackingCandidateId: qsParams.trackingCandidateId
        }
      })

    if(preloadProfile && !qsParams.alloweditpostulation)
      getProfileExhaustiveQuery({
        variables: {
          jobId: params.jobId ?? qsParams.jobId!
        }
      })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if(getThemeQuery.loading || getJobQuery.loading || getCandidateQueryResult.loading) return (
    <Loading />
  )

  if(getJobQuery.error?.message?.includes('Job NotFound')) return <Redirect to='/' />

  if(getThemeQuery.error || getJobQuery.error) return (
    <div />
  )

  if(!matchPath(window.location.pathname, {
    exact: true,
    path : '/merge/:jobId/publication/:publicationIndex'
  }) && getCandidateQueryResult.error)
    return <div />

  return (
    <>
      <Helmet>
        {(NODE_ENV === 'development' && cssTextFamily) ?
          <style id='fonts-css'>{cssTextFamily}</style> :
          <link href='https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap' rel='stylesheet' />
        }
        <title>{getJobQuery.data?.job.publications[0]?.title}</title>
      </Helmet>
      <ThemeProvider theme={themeGenerated}>
        <CssGlobal />
        <MuiPickersUtilsProvider locale={esLocale} utils={DayjsUtils}>
          <>
            {children}
            {!inIframe() && preloadCandidate && (
              <TaskReminder
                jobId={params.jobId ?? qsParams.jobId!}
                publicationIndex={Number(params.publicationIndex ?? qsParams.publicationIndex ?? 0)} />
            )}
          </>
        </MuiPickersUtilsProvider>
      </ThemeProvider>
    </>
  )
}

export default ThemeCustomProvider
