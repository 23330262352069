import qs from 'query-string'

export interface Location {
  search: string;
  pathname: string;
}

export function getSearchParams<T = any>(_location?: Location) {
  const location = _location ?? window.location

  const params: T = qs.parse(location.search) as any

  return params
}
