import { FC } from 'react'
import { Typography, makeStyles, Link, Button } from '@krowdy/kds-core'
import { useSnackbar } from 'notistack'
import clsx from 'clsx'
import queryString from 'query-string'

const StorageErrorPage: FC<{}> = () => {
  const classes = useStyles()
  const { enqueueSnackbar } = useSnackbar()

  const { source } = queryString.parse(window.location.search)

  const _handleCopySourceLink = () => {
    navigator.clipboard.writeText(String(source))
      .then(() => enqueueSnackbar('Vinculo copiado al portapapeles.', { variant: 'success' }))
      .catch(error => {
        enqueueSnackbar('No se pudo copiar el vinculo', { variant: 'error' })
        // eslint-disable-next-line no-restricted-syntax
        console.error(error)
      })
  }

  return (
    <div className={classes.root}>
      <div className={classes.center}>
        <img alt='chrome-alt' src='https://cdn.krowdy.com/media/images/ChromeStartIcon.svg' />
        <Typography className={classes.tittle} variant='h4'>Ingresa desde Chrome</Typography>
        <Typography className={clsx(classes.mgt, classes.gray700)} variant='body3'>El navegador desde el que estás accediendo no es compatible con nuestra app de postulación. 😥</Typography>
        <Typography className={clsx(classes.mgt2, classes.gray700)} variant='body3'>Ingresa al siguiente link desde Google Chrome (revisa que esté actualizado) para continuar con tu postulación.</Typography>
        <Typography
          className={clsx(classes.mgt2, classes.width100)}
          variant='body3'>
          <Link className={classes.source} href='#'>
            {source}
          </Link>
        </Typography>
        <Button
          className={classes.mgt2}
          color='primary'
          onClick={_handleCopySourceLink}
          variant='contained'>Copiar Vinculo</Button>
      </div>
    </div>
  )
}

const useStyles = makeStyles((theme) => ({
  center: {
    alignItems   : 'center',
    display      : 'flex',
    flexDirection: 'column',
    maxWidth     : 500,
    textAlign    : 'center',
    width        : '100%'
  },
  gray700: {
    color: theme.palette.grey[700]
  },
  mgt: {
    marginTop: theme.spacing(1.5)
  },
  mgt2: {
    marginTop: theme.spacing(3)
  },
  root: {
    alignItems    : 'center',
    display       : 'flex',
    flexDirection : 'column',
    height        : '100vh',
    justifyContent: 'center',
    overflow      : 'hidden',
    padding       : theme.spacing(2.5),
    width         : '100vw'
  },
  source: {
    display : 'block',
    width   : '100%',
    wordWrap: 'break-word'
  },
  tittle: {
    color    : theme.palette.grey[800],
    marginTop: theme.spacing(5)
  },
  width100: {
    width: '100%'
  }
}), { name: 'StorageErrorPage' })

export default StorageErrorPage
