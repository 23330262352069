import { memo, FC, ReactNode, isValidElement } from 'react'
import { makeStyles, Typography } from '@krowdy/kds-core'
import { KrowdyTypographyProps } from '@krowdy/kds-core/Typography'
import clsx from 'clsx'
interface ContentImageProps {
  title?: String;
  content?: ReactNode;
  Image?: any;
  contentTypographyProps?: KrowdyTypographyProps;
  className?: string;
}

const ContentImage: FC<ContentImageProps> = ({
  title,
  content,
  Image,
  contentTypographyProps,
  className
}) => {
  const classes = useStyles()

  return (
    <div className={clsx(classes.container, className)}>
      {
        Image ? <Image color='custom' width={320} /> : null
      }
      <Typography className={classes.title} variant='h5'>
        {title}
      </Typography>
      { isValidElement(content) ? (
        content
      ) : (
        <Typography
          className={classes.content}
          component='div'
          {...contentTypographyProps}
          variant='body2'>
          {content}
        </Typography>
      )}
    </div>
  )
}

const useStyles = makeStyles(({ palette, spacing }) => ({
  container: {
    alignItems    : 'center',
    display       : 'flex',
    flexDirection : 'column',
    height        : '100%',
    justifyContent: 'center',
    padding       : spacing(2.5)
  },
  content: {
    color     : palette.grey[700],
    textAlign : 'justify',
    whiteSpace: 'pre-line'
  },
  title: {
    fontWeight: 700,
    margin    : spacing(2.5, 0, 1.5, 0)
  }
}), { name: 'ContentImage' })
export default memo(ContentImage)
