import urlParser from 'url-parse'
import { Maybe, Profile } from '__generated__/typescript-operations'
import { PathContentForm } from './constants'
import { isCandidatePublicView } from './flows'

interface GetUrlExecArgs {
  urlExec: string;
  codeTask: string;
}

const version = 'v2'
/*
  REACT_APP_FORM
*/
export const getUrlExec = ({ urlExec, codeTask }: GetUrlExecArgs) => {
  let url = process.env[`REACT_APP_${codeTask.toUpperCase()}`]

  if(!url)
    url = urlExec

  const parseUrl = urlParser(url)

  const standardExecPath = `${parseUrl.origin}/${version}/:codeTask/:resultTaskId`

  return standardExecPath
}

export const getUrlExecExternal = (codeTask: string) => {
  let urlDefault = process.env.REACT_APP_BASE_FRONT_URL

  const parseUrl = urlParser(urlDefault!)

  let standardExecPath = `${parseUrl.origin}/internalapps/:codeTask/:resultTaskId/:jobId`

  if(codeTask === PathContentForm.VideoInterview && isCandidatePublicView())
    standardExecPath = `${window.location.origin}/internalapps/:codeTask/:resultTaskId/:jobId`

  return standardExecPath
}

export const replaceAll = (str:string, find:string, replace:string) => str.replace(new RegExp(find, 'g'), replace)

export const getWidth = (cols = 0) => {
  const GAP = 8
  const TOTALS_COLS = 12

  const COL_SIZE = (window.innerWidth - 2 * 83 - (TOTALS_COLS - 1) * GAP) / TOTALS_COLS

  return COL_SIZE * cols + (cols - 1) * GAP
}

export class LocalStorageExpiration {
  key: string;
  defaultValue: any;
  expiration: number;
  constructor(key: string, defaultValue: any, expiration: number) {
    this.key = key
    this.defaultValue = defaultValue
    this.expiration = expiration
  }

  setValue(value: any) {
    const obj = {
      expirationDate: new Date(new Date().getTime() + this.expiration),
      value         : JSON.stringify(value)
    }
    const objString = JSON.stringify(obj)
    localStorage.setItem(this.key, objString)

    return objString
  }

  getValue() {
    let valueString = localStorage.getItem(this.key)

    if(!valueString)
      valueString = this.setValue(this.defaultValue)

    let data = JSON.parse(valueString)

    if(new Date(data.expirationDate).getTime() <= new Date().getTime()) {
      valueString = this.setValue(this.defaultValue)
      data = JSON.parse(valueString)
    }

    return data.value
  }
}

export const parseCompanyName = (companyName: string, visibleInformation = false) => {
  const company_name_normalize = companyName.normalize('NFD').replace(/[\u0300-\u036f]/g, '')

  return !visibleInformation ? 'confidencial' : encodeURIComponent(company_name_normalize.replace(/\./g, '-').replace(/ /g, '-'))
}

export const parseJobTitle = (jobTitle: string) => {
  const job_title_normalize = jobTitle.normalize('NFD').replace(/[\u0300-\u036f]/g, '')

  return encodeURIComponent(job_title_normalize.replace(/\./g, '-').replace(/ /g, '-')).slice(0, 30)
}

export const sleep = (ms: number) => new Promise(r => setTimeout(r, ms))

export const removeAccents = (str: string) => str.normalize('NFD').replace(/[\u0300-\u036f]/g, '')

export const getFirstEmailProfile = (profile: Partial<Pick<Profile, 'emails'>> & { email?: Maybe<string>; }) => {
  if(!profile) return ''

  if(profile.email) return profile.email

  if(!profile.emails?.length) return ''

  const [ firstEmail ] = profile.emails

  if(!firstEmail?.value) return ''

  return firstEmail.value
}

export const getFirstPhoneProfile = (profile: Partial<Pick<Profile, 'phones'>> & { phone?: Maybe<string>; }): string => {
  if(!profile) throw new Error('profile is required')
  if(!profile.phones?.length) return ''

  const [ firstPhone ] = profile.phones

  if(!firstPhone?.value) return ''

  return firstPhone.value
}

export const validUrlLinkedin = (linkVerification: string) => {
  const pattern = new RegExp('^(https?:\\/\\/)?' + // protocol
  '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
  '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
  '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
  '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
  '(\\#[-a-z\\d_]*)?$', 'i') // fragment locator

  let isValid = true
  if(!pattern.test(linkVerification))
  {
    isValid = false
  }
  else
  {
    const newstring = linkVerification.replace('https://www.linkedin.com/in/', 'linkedin/')
    const valuesname = newstring.split('/')
    if(valuesname[0] !== 'linkedin')
      isValid = false
  }

  return isValid
}

export const swap = (object: any) => {
  const ret: any = {}
  for (let key in object) ret[object[key]] = key

  return ret
}

export const omit = (obj: any, fields: string[] = []) => Object.keys(obj).reduce((acc, key: string) => {
  if(fields.includes(key)) return acc

  return {
    ...acc,
    [key]: obj[key]
  }
}, {})

export const getUrlFlagByCountryCode = (countryCode: string) => `https://cdn.krowdy.com/countries/flags/${countryCode.toUpperCase()}.png`

const patternDocNumberDefault = new RegExp(/^\d+$/)

export const TypeDocNumber = {
  curp           : 'CURP',
  dni            : 'dni',
  immigrationCard: 'immigrationCard',
  passport       : 'passport',
  rfc            : 'RFC',
  rut            : 'RUT'
}

export const DocNumberConfigs = {
  [TypeDocNumber.dni]: {
    isNumeric  : true,
    label      : 'DNI',
    message    : 'Ingrese un DNI válido de 8 dígitos.',
    pattern    : new RegExp(/^\d{8}$/),
    placeholder: 'ej. 12345678',
    slug       : 'DNI'
  },
  [TypeDocNumber.passport]: {
    isNumeric  : true,
    label      : 'Pasaporte',
    message    : 'Ingrese un pasaporte válido hasta 20 dígitos.',
    pattern    : patternDocNumberDefault,
    placeholder: 'ej. 00200001',
    slug       : 'Pasaporte'
  },
  [TypeDocNumber.immigrationCard]: {
    isNumeric  : true,
    label      : 'Carnet de Extranjería',
    message    : 'Ingrese un carnet de extranjería válido hasta 20 dígitos.',
    pattern    : patternDocNumberDefault,
    placeholder: 'ej. 00100101',
    slug       : 'CE'
  },
  [TypeDocNumber.curp]: {
    isNumeric  : false,
    label      : 'CURP',
    message    : 'Ingrese un CURP válido de 18 dígitos.',
    // eslint-disable-next-line max-len
    pattern    : new RegExp(/^([A-Z][AEIOUX][A-Z]{2}\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])[HM](?:AS|B[CS]|C[CLMSH]|D[FG]|G[TR]|HG|JC|M[CNS]|N[ETL]|OC|PL|Q[TR]|S[PLR]|T[CSL]|VZ|YN|ZS)[B-DF-HJ-NP-TV-Z]{3}[A-Z\d])(\d)$/),
    placeholder: 'ej. OEAF771012HMCRGR09',
    slug       : 'CURP'
  },
  [TypeDocNumber.rfc]: {
    isNumeric  : false,
    label      : 'RFC',
    message    : 'Ingrese un RFC válido hasta 13 dígitos.',
    pattern    : new RegExp(/^([A-ZÑ&]{3,4}) ?(?:- ?)?(\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])) ?(?:- ?)?([A-Z\d]{2})([A\d])$/),
    placeholder: 'ej. XAXX010101000',
    slug       : 'RFC'
  },
  [TypeDocNumber.rut]: {
    isNumeric  : false,
    label      : 'RUT',
    message    : 'Ingrese un RUT válido hasta 9 dígitos.',
    pattern    : new RegExp(/\b(\d{1,3}(?:\.\d{1,3}){2}-[\dkK])\b/),
    placeholder: 'ej. 111.111.111-1',
    slug       : 'RUT'
  }
}
