import clsx from 'clsx'
import { Typography, makeStyles, Link } from '@krowdy/kds-core'
import queryString from 'query-string'
import { useMemo } from 'react'

const NotFound = () => {
  const classes = useStyles()

  const queryStringParams = useMemo(() => queryString.parse(window.location.search), [])
  const preventDefault = (event: any) => {
    event.preventDefault()
    window.location.href = 'https://chamba.laborum.pe'
  }

  return (
    <div className={classes.imageContainer}>
      <div className={classes.container}>
        <img
          alt='Landing'
          className={clsx(classes.image, classes.largeMarginTop)}
          src='https://cdn.krowdy.com/media/images/Ilustracion404.svg' />
        <Typography
          color='primary'
          variant='h4'>
            Ups
        </Typography>
        {queryStringParams.message ? (
          <>
            <Typography
              className={classes.largeMarginTop}
              variant='body3'>
              {queryStringParams.message} <span aria-label='monkey' role='img'>🙊</span> </Typography>
          </>
        ) : (
          <>
            <Typography
              className={classes.largeMarginTop}
              variant='body3'>
              <br />
          Este proceso de selección se encuentra finalizado.
              <br />
            </Typography>
            <Typography
              className={classes.largeMarginTop}
              variant='body3'>No te preocupes puedes seguir revisando tus demás ofertas laborales en Laborum</Typography>

            <Typography
              className={classes.largeMarginTop}
              variant='body3'>
              <Link href='#' onClick={preventDefault}>
                  https://chamba.laborum.pe
              </Link>
            </Typography>
          </>
        )}

      </div>
    </div>
  )
}

const useStyles = makeStyles((theme) => ({
  container: {
    alignItems   : 'center',
    display      : 'flex',
    flexDirection: 'column',
    maxWidth     : 500,
    textAlign    : 'center'
  },
  image: {
    width: '100%'
  },
  imageContainer: {
    alignItems    : 'center',
    display       : 'flex',
    flexDirection : 'column',
    height        : '100vh',
    justifyContent: 'center',
    overflow      : 'hidden',
    padding       : theme.spacing(3),
    width         : '100vw'
  },
  largeMarginTop: {
    marginTop: theme.spacing(2.5)
  },
  marginTop: {
    marginTop: theme.spacing(1.5)
  },
  title: {
    color     : '#cfcecf',
    fontFamily: 'Roboto, sans-serif',
    fontSize  : '1.2rem'
  }
}), { name: 'NotFound' })

export default NotFound
