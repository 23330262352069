import { FC, ReactNode, useEffect } from 'react'
import { useHistory, generatePath  } from 'react-router'

import { useLoginRequired } from 'hooks/useLoginRequired'
import Loading from 'components/Loading'
import queryString from 'query-string'

interface IProps {
  children?: ReactNode;
  // any other props that come into the component
}

const PrivateRoute: FC<IProps> = ({ children, ...rest }) => {
  const { match: computedMatch } = rest as any
  const { params } = computedMatch
  const { jobId, publicationIndex } = params
  const history = useHistory()
  const data = useLoginRequired()

  const queryStringParams = queryString.parse(window.location.search) as any

  delete queryStringParams?.trackingCandidateId

  useEffect(() => {
    history.push({
      pathname: generatePath('/job/:jobId/publication/:publicationIndex', {
        jobId           : jobId,
        publicationIndex: publicationIndex
      }),
      search: queryString.stringify({
        ...queryStringParams
      })
    })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if(data.loading) return (<Loading />)
  if(!children) return null

  return <>{children}</>
}

export default PrivateRoute

