import { useMemo, useState } from 'react'
import clsx from 'clsx'
import { Button, CircularProgress, makeStyles, Typography } from '@krowdy/kds-core'
import { useParams } from 'react-router-dom'

import { Theme } from 'utils/theme'
import { useGetTaskById } from 'hooks/task'
import { useGetUser } from 'containers/SupportLiveChat/apollo/hooks'
import { useGetJob } from 'layouts/Main/apollo/hooks'
import { useGetCandidateTaskByTaskAndUser } from './apollo/hooks'
import { useCreateResultTask } from 'pages/_land/apollo/hooks'

import CandidatesLobby from './CandidatesLobby'
import ValidateExecuterPopup from './ValidateExecuterPopup'
import Loading from 'components/Loading'
import Ups from './Ups'

import { LobbyPageParams } from 'interfaces/pages/_lobby'

import { completeStatuses } from '../../utils/constants'
import ThemeCustomProvider from 'layouts/Main/ThemeCustomProvider'

enum Rol {
  Candidate = 'candidate',
  Responsable = 'responsable'
}

const LobbyPage = () => {
  const classes = useStyles()

  const params = useParams<LobbyPageParams>()

  const [ isReviewer, setIsReviewer ] = useState(false)
  const [ openModal, setOpenModal ] = useState(false)

  const getUserQuery = useGetUser()
  const getTaskQuery = useGetTaskById({ variables: { taskId: params.taskId } })
  const getJobQuery = useGetJob({
    variables: {
      jobId           : params.jobId,
      publicationIndex: Number(params.publicationIndex ?? 0)
    }
  })
  const getCandidateTaskByTaskAndUserQuery = useGetCandidateTaskByTaskAndUser({
    variables: {
      taskId: params.taskId
    }
  })

  const [ createResultTask, createResultTaskResult ] = useCreateResultTask()

  const rol = useMemo(() =>
    getCandidateTaskByTaskAndUserQuery.data?.getCandidateTaskByTaskAndUser ? Rol.Candidate : Rol.Responsable
  , [ getCandidateTaskByTaskAndUserQuery.data?.getCandidateTaskByTaskAndUser ])

  const user = useMemo(() => getUserQuery.data?.getUser, [ getUserQuery.data?.getUser ])
  const task = useMemo(() => getTaskQuery.data?.getTaskById, [ getTaskQuery.data?.getTaskById ])
  const job = useMemo(() => getJobQuery.data?.job, [ getJobQuery.data?.job ])
  const candidateTask = useMemo(() =>
    getCandidateTaskByTaskAndUserQuery.data?.getCandidateTaskByTaskAndUser
  , [ getCandidateTaskByTaskAndUserQuery.data?.getCandidateTaskByTaskAndUser ])

  /* Redirige al candidato a la plataforma video entrevista */
  const _handleCandidateExec = async () => {
    let newResultTaskId = candidateTask?.resultTaskId

    if(!candidateTask?.resultTaskId && candidateTask?._id) {
      const createResultTaskResult = await createResultTask({
        variables: {
          candidateTaskId: candidateTask._id,
          jobId          : params.jobId,
          taskId         : params.taskId
        }
      })

      newResultTaskId = createResultTaskResult.data?.createResultTask.resultTaskId
    }

    if(candidateTask?.categoryTask?.urlExec && newResultTaskId)
      window.location.href = `${candidateTask?.categoryTask?.urlExec}/${newResultTaskId}`
  }

  const _handleClickGoToTask = () => setOpenModal(true)
  const _handleClose = () => setOpenModal(false)

  const _handleSubmitValidateExecuterPopUp = (validation: boolean) => {
    setIsReviewer(validation)
  }

  const loading = useMemo(() =>
    getUserQuery.loading ||
    getTaskQuery.loading ||
    getJobQuery.loading ||
    getCandidateTaskByTaskAndUserQuery.loading
  , [ getUserQuery.loading, getTaskQuery.loading, getJobQuery.loading, getCandidateTaskByTaskAndUserQuery.loading ])

  const error = useMemo(() =>
    getUserQuery.error ||
    getTaskQuery.error ||
    getJobQuery.error ||
    getCandidateTaskByTaskAndUserQuery.error
  , [ getUserQuery.error, getTaskQuery.error, getJobQuery.error, getCandidateTaskByTaskAndUserQuery.error ])

  if(loading) return (
    <Loading />
  )

  if(error || !rol || !task?.isActive)
    return (
      <Ups />
    )

  /*
    cuando el usuario ya selecciono o ingreso manualmente el codigo de la tarea
    solo puede acceder a este flujo aquel usuario que no tiene candidateTask sobre dicha tarea
  */
  if(isReviewer)
    return (
      <div className={clsx(classes.container, classes.isReviewer)} >
        <div className={classes.image}>
          <img alt='user-img' src='https://cdn.krowdy.com/images/user.png' />
        </div>
        <div className={classes.section}>
          <Typography className={classes.nameText} variant='h3'>
            ¡Hola {user?.firstName}!
          </Typography>
          <Typography className={clsx(classes.margins, classes.content)} variant='body3' >
            Bienvenido a la {task.alias} de <span className={classes.jobTitle}>{job?.title}</span>
          </Typography>
          <CandidatesLobby jobId={params.jobId} taskId={params.taskId} />
        </div>
      </div>
    )

  return (
    <div className={classes.container} >
      <div className={classes.image}>
        <img alt='user-img' src='https://cdn.krowdy.com/images/user.png' />
      </div>
      <div className={classes.section} >
        <Typography className={classes.nameText} variant='h3'>
          ¡Hola {user?.firstName}!
        </Typography>
        {(candidateTask?.status && completeStatuses.includes(candidateTask?.status)) ? (
          <Typography className={clsx(classes.margins, classes.content)} variant='body3'>
            Tu {task.alias} de <span className={classes.jobTitle}>{job?.title}</span> ya fue realizada
          </Typography>
        ) : (
          <>
            <Typography className={clsx(classes.margins, classes.content)} variant='body3'>
              Bienvenido a la {task.alias} de <span className={classes.jobTitle}>{job?.title}.</span>
              En unos momentos, el entrevistador te permitirá acceder
            </Typography>
            <Typography className={clsx(classes.margins, classes.content)} variant='body3'>
              Antes de ser entrevistado, comprobemos unos detalles.
            </Typography>
            {
              rol === Rol.Candidate ? (
                <Button
                  className={classes.margins}
                  color='primary'
                  onClick={_handleCandidateExec}
                  variant='contained'>
                  {!createResultTaskResult.loading ? 'Iniciar test' : <CircularProgress size={25} />}
                </Button>) : (
                <>
                  <Button
                    className={clsx(classes.margins, classes.executerButton)}
                    color='primary'
                    onClick={_handleClickGoToTask}>
                    Acceder como entrevistador
                  </Button>
                  <ValidateExecuterPopup
                    onClose={_handleClose}
                    onSubmit={_handleSubmitValidateExecuterPopUp}
                    open={openModal}
                    taskId={task._id} />
                </>
              )
            }
          </>
        )}
      </div>
    </div>
  )
}

const useStyles = makeStyles<Theme>((theme) => ({
  container: {
    alignContent  : 'center',
    alignItems    : 'center',
    background    : theme.palette.secondary[600],
    display       : 'flex',
    flexDirection : 'column',
    height        : '100vh',
    justifyContent: 'center',
    justifyItems  : 'center',
    width         : '100%'
  },
  content: {
    color: theme.palette.secondary[10]
  },
  contentAccess: {
    alignItems   : 'center',
    display      : 'flex',
    flexDirection: 'column',
    maxWidth     : 340
  },
  executerButton: {
    color: theme.palette.primary[500]
  },
  image: {
    margin   : theme.spacing(5),
    maxHeight: 277,
    maxWidth : 500
  },
  isReviewer: {
    display      : 'flex',
    flexDirection: 'row'
  },
  jobTitle: {
    fontWeight: 'bold'
  },
  keyInput: {
    border      : `1px solid ${theme.palette.secondary[100]}`,
    borderRadius: 4,
    margin      : theme.spacing(2.5, 0),
    padding     : theme.spacing(0, 1)
  },
  margins: {
    marginTop: theme.spacing(2.5)
  },
  nameText: {
    color: theme.palette.krowdy[400]
  },
  section: {
    alignItems    : 'center',
    display       : 'flex',
    flexDirection : 'column',
    justifyContent: 'center',
    justifyItems  : 'center',
    maxWidth      : 400,
    textAlign     : 'center'
  }
}), { name: 'LobbyPage' })

const WithTheme = () => (
  <ThemeCustomProvider preloadCandidate={false} preloadProfile={false}>
    <LobbyPage />
  </ThemeCustomProvider>
)

export default WithTheme
