import { FC, memo } from 'react'
import clsx from 'clsx'
import { Button, makeStyles, Typography } from '@krowdy/kds-core'
import { Theme } from 'utils/theme'

const Ups: FC = () => {
  const classes = useStyles()

  const _handleClickGoToLaborum = () => {
    window.location.href = process.env.REACT_APP_LABORUM_SITE!
  }

  return (
    <div className={clsx(classes.container)} >
      <div className={classes.image}>
        <img alt='user-img' src='https://cdn.krowdy.com/images/frames/Frame+1054.png' />
      </div>
      <div className={classes.section}>
        <Typography className={classes.nameText} variant='h3'>
          ¡Upss!
        </Typography>
        <Typography className={clsx(classes.margins, classes.content)} variant='body3' >
          Lo lamentamos, no pudimos encontrar esta tarea.
          Puede que este link haya expirado o este erróneo
          <span aria-label='role' role='img'> 🙊 </span>
        </Typography>
        <Typography className={clsx(classes.margins, classes.content)} variant='body3' >
          Si deseas, te podemos mostrar miles de puestos disponibles en Laborum
        </Typography>
        <Button
          className={classes.margins}
          color='primary'
          onClick={_handleClickGoToLaborum}
          variant='contained' >
          Explorar puestos disponibles
        </Button>
      </div>
    </div>
  )
}

const useStyles = makeStyles<Theme>((theme) => ({
  container: {
    alignContent  : 'center',
    alignItems    : 'center',
    background    : theme.palette.secondary[600],
    display       : 'flex',
    flexDirection : 'column',
    height        : '100vh',
    justifyContent: 'center',
    justifyItems  : 'center',
    width         : '100%'
  },
  content: {
    color: theme.palette.secondary[10]
  },
  image: {
    margin   : theme.spacing(5),
    maxHeight: 277,
    maxWidth : 500
  },
  margins: {
    marginTop: theme.spacing(2.5)
  },
  nameText: {
    color: theme.palette.krowdy[400]
  },
  section: {
    alignItems    : 'center',
    display       : 'flex',
    flexDirection : 'column',
    justifyContent: 'center',
    justifyItems  : 'center',
    maxWidth      : 400,
    textAlign     : 'center'
  }
}), { name: 'Ups' })

export default memo(Ups)
