import { FC, ReactNode } from 'react'
import { useEditPostulation } from 'hooks/useEditPostulation'
import Loading from 'components/Loading'
import { useParams } from 'react-router-dom'
import useSearchParams from 'hooks/useSearchParams'
import { useLoginRequired } from 'hooks/useLoginRequired'
import { inIframe } from 'utils/iframe'

interface IProps {
  children?: ReactNode;
  // any other props that come into the component
}

const EditPostulationRoute: FC<IProps> = ({ children }) => {
  const params = useParams<{ candidateId: string; jobId: string; }>()
  const qsParams = useSearchParams<{ slug?: string; utm_source?: string; }>()

  const loginRequiredQuery = useLoginRequired({
    skip: !inIframe()
  })

  const editPostulationQuery = useEditPostulation({
    candidateId: params.candidateId,
    jobId      : params.jobId,
    slug       : qsParams?.slug,
    sourceApply: qsParams?.utm_source
  })

  if(editPostulationQuery.loading || (inIframe() && loginRequiredQuery.loading)) return (<Loading />)

  if(!children) return null

  return <>{children}</>
}

export default EditPostulationRoute

