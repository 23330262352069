import { CandidateTaskStatus } from '__generated__/typescript-operations'

export const LOCAL_STORAGE_KEYS = {
  ACCESS_TOKEN          : 'accessToken',
  CANDIDATE_ACCESS_TOKEN: 'candidate_access_token',
  EMAIL                 : 'email',
  FIRSTNAME             : 'firstName',
  LASTNAME              : 'lastName',
  MIXPANEL_SESSION      : 'mixpanelSession',
  NUMBER_LOGINS         : 'numberLogins',
  REFRESH_TOKEN         : 'refreshToken',
  SEND_PROFILE_COUNTER  : 'send_profile_counter',
  START_SESSION_DATE    : 'start_session_date',
  TOTAL_ERRORS          : 'total_errors',
  USER_ID               : 'iduser'
}

export const hierarchies = [
  {
    name : '',
    value: ''
  },
  {
    name : 'Asistente',
    value: 'Asistente'
  },
  {
    name : 'Tecnico / operativo',
    value: 'Tecnico / operativo'
  },
  {
    name : 'Ejecutivo / representante',
    value: 'Ejecutivo / representante'
  },
  {
    name : 'Supervisor / coordinador',
    value: 'Supervisor / coordinador'
  },
  {
    name : 'Practicante - pre - profesional',
    value: 'Practicante - pre - profesional'
  },
  {
    name : 'Jefatura',
    value: 'Jefatura'
  },
  {
    name : 'Analista',
    value: 'Analista'
  },
  {
    name : 'Practicante - profesional',
    value: 'Practicante - profesional'
  },
  {
    name : 'Gerencia',
    value: 'Gerencia'
  },
  {
    name : 'Dirección',
    value: 'Dirección'
  }
]

export enum LevelType {
  Secondary = 'Secundaria',
  Institute = 'Instituto',
  Graduate = 'Universitario',
  Technical = 'Curso/Taller',
  Diplomat = 'Diplomado',
  Certification = 'Certificación'
}

export const levels = [
  { name: LevelType.Secondary, value: LevelType.Secondary },
  { name: LevelType.Institute, value: LevelType.Institute },
  { name: LevelType.Graduate, value: LevelType.Graduate },
  { name: LevelType.Technical, value: LevelType.Technical },
  { name: LevelType.Diplomat, value: LevelType.Diplomat },
  { name: LevelType.Certification, value: LevelType.Certification }
]

export enum ConditionType {
  Complete = 'Completo',
  Incomplete = 'Incompleto',
  Studying = 'Estudiando'
}

export const conditions = [
  { name: ConditionType.Complete, value: ConditionType.Complete },
  { name: ConditionType.Incomplete, value: ConditionType.Incomplete },
  { name: ConditionType.Studying, value: ConditionType.Studying }
]

export const degrees = [
  { name: 'Bachiller', value: 'Bachiller' },
  { name: 'Licenciatura', value: 'Licenciatura' },
  { name: 'Postgrado', value: 'Postgrado' },
  { name: 'Maestría', value: 'Maestría' },
  { name: 'Doctorado', value: 'Doctorado' }
]

export const placeholdersBasicInformation = {
  birthDate        : 'dd/mm/aaaa',
  countryCodeSearch: 'Busca un código o país',
  currencySearch   : 'Busca un país',
  docNumber        : 'ej. 12345678',
  email            : 'ej. jose.perez@correo.com',
  firstName        : 'ej. José',
  gender           : 'ej. Masculino',
  lastName         : 'ej. Pérez',
  location         : 'ej. Lima, Perú',
  nationality      : 'ej. Perú',
  phone            : '999 999 999',
  salaryMount      : 'ej. 1,000'
}

export const placeholdersResidence = {
  city    : 'ej. Barranca',
  country : 'ej. Perú',
  district: 'ej. Comas',
  region  : 'ej. Lima'
}

export const placeholdersResidenceExt = {
  city  : 'ej. Olympia',
  region: 'ej. Washington'
}

export const placeholdersExperiencies = {
  area       : 'ej. Recursos Humanos',
  companyName: 'ej. Krowdy',
  endDate    : 'mm/aaaa',
  hierarchy  : 'ej. Asistente',
  startDate  : 'mm/aaaa'
}

export const placeholdersStudies = {
  degree         : 'ej. Bachiller',
  endDate        : 'mm/aaaa',
  institutionName: 'ej. Krowdy Academy',
  level          : 'ej. Curso/Taller',
  startDate      : 'mm/aaaa '
}

export const placeholdersReferents = {
  companyName    : 'ej: bcp, interbank',
  companyReferent: 'ej: bcp, interbank',
  name           : 'ej. Luis Gonzales',
  phoneNumber    : 'ej. 999 999 999',
  position       : 'ej. Jefe de Finanzas'
}

export const placeholderAditional:{[key:string]: string} = {
  knowledge: 'ej. Excel',
  websites : 'www.linkedin.com'
}

export enum PathContentForm {
  BackgroundCheck = 'background-check',
  VideoInterview = 'video-interview',
  VideoQuestionnaire = 'video-questionnaire',
  TestOnline = 'test-online',
  Tracking = 'tracking',
  VideoPresentation = 'video-presentation',
  Form = 'form',
  FormCall = 'form-call',
  Curriculum = 'cv',
  Hunting = 'hunting',
  Ranking = 'ranking',
  Invitation = 'invitation',
  DocumentRequest = 'document-request',
  TestOnlineMultitest = 'test-online-multitest',
  Attraction = 'attraction',
  OutsideTest = 'outside-test',
  OpenTest = 'open-test',
  KrowderConsulting = 'krowder-consulting',
  OpenTestFuzzyNatura = 'open-test-fuzzy-natura',
  OpenTestFuzzyNaturaCompetencies = 'open-test-fuzzy-natura-competencies',
  EventSchedule = 'event-schedule'
}

export enum BrowserTypes {
  Safari = 'Safari'
}
export enum PhoneEmailTypes {
  Chatbot = 'chatbot',
  PhonePrincipal='phone-principal'
}

export const messageError = '¡Lo sentimos algo ha ido mal y no podemos realizar la siguiente acción!'

export enum ActionCrudType {
  Add = 'add',
  Update = 'update',
  Remove = 'remove'
}

const candidateTaskStatuses = [
  CandidateTaskStatus.Pending,
  CandidateTaskStatus.Doing,
  CandidateTaskStatus.Executed,
  CandidateTaskStatus.Reviewed
]

export const completeStatuses = [ CandidateTaskStatus.Executed, CandidateTaskStatus.Reviewed ]

export const incompleteStatuses = candidateTaskStatuses.filter((status) => !completeStatuses.includes(status))

export const FullScreenActive = 'active'

export enum GeolocationEvents {
  EnterToGeolocationPage = 'enterToGeolocationPage',
  ClickAtEnableLocation = 'clickAtEnableLocation',
  LeaveGeolocationPage = 'leaveGeolocationPage'
}

export enum ParentSourceOrigin {
  Gats = 'GATS',
  Krocon = 'KROCON'
}

export const METER_PER_KMETER = 1000
