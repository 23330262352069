import { makeStyles } from '@krowdy/kds-core'

export default () => {
  const classes = useStyles()

  return (
    <div
      className={classes.root}>
      <img alt='loading' src='https://cdn.krowdy.com/images/loader.gif' />
    </div>
  )
}

const useStyles = makeStyles(() => ({
  root: {
    alignItems     : 'center',
    backgroundColor: '#f2f4f7',
    display        : 'flex',
    height         : '100%',
    justifyContent : 'center',
    left           : 0,
    position       : 'absolute',
    top            : 0,
    width          : '100%'
  }
}), { name: 'Loading' })
