import { useQuery } from '@apollo/client'
import { loader } from 'graphql.macro'
import {
  GetUser_SupportLiveChatQuery,
  GetUser_SupportLiveChatQueryVariables
} from '__generated__/typescript-operations'

const GET_USER = loader('./getUser.graphql')

export const useGetUser = () =>
  useQuery<GetUser_SupportLiveChatQuery, GetUser_SupportLiveChatQueryVariables>(GET_USER)
