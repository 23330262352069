import { MutationHookOptions, QueryHookOptions, useApolloClient, useMutation, useQuery, useLazyQuery, LazyQueryHookOptions, QueryOptions } from '@apollo/client'
import { loader } from 'graphql.macro'
import {
  GetLocationsQuery,
  GetLocationsQueryVariables,
  GetCountryCode_ProfileQueryVariables,
  GetCountryCode_ProfileQuery,
  UpdateProfileBasicInformationMutation,
  UpdateProfileBasicInformationMutationVariables,
  GetProfileExhaustive_LandQuery,
  GetProfileExhaustive_LandQueryVariables,
  GetBranchOfficesByJobIdQueryVariables,
  UpdateCvMutation,
  UpdateCvMutationVariables,
  UpdateExperienceMutation,
  UpdateExperienceMutationVariables,
  UpdateReferentsMutation,
  UpdateReferentsMutationVariables,
  UpdateEducationMutation,
  UpdateEducationMutationVariables,
  UpdateAdditionalInformationMutation,
  UpdateAdditionalInformationMutationVariables,
  UpdateProfileCareerMutation,
  UpdateProfileCareerMutationVariables,
  GetCareerQuery,
  UpdateLastProfileSectionCodeMutation,
  UpdateLastProfileSectionCodeMutationVariables,
  GetProfileDateOnetExpiredQueryVariables,
  QueryGetFlowPostulationByProfileIdArgs,
  GetFlowPostulationByProfileIdQueryVariables,
  UpdateCandidateProfileMutation,
  UpdateCandidateProfileMutationVariables,
  UpdateProfileByGeoLocationMutation,
  UpdateProfileByGeoLocationMutationVariables,
  GetRegionsByCountryCodeQuery,
  GetRegionsByCountryCodeQueryVariables,
  GetCitiesByRegionNameQuery,
  GetCitiesByRegionNameQueryVariables,
  GetDistrictsByCityNameQuery,
  GetDistrictsByCityNameQueryVariables,
  UpdateProfileCoordinatesMutation,
  UpdateProfileCoordinatesMutationVariables,
  GetBranchOfficesByJobIdQuery,
  UpdateBranchOfficeCandidatesMutation,
  UpdateBranchOfficeCandidatesMutationVariables,
  GetAllBranchOfficesByJobIdQueryVariables,
  GetAllBranchOfficesByJobIdQuery
} from '__generated__/typescript-operations'
import produce from 'immer'

const GET_COUNTRY_CODE = loader('./getCountryCode.graphql')
const UPDATE_PROFILE_BASIC_INFORMATION = loader('./updateProfileBasicInformation.graphql')
export const GET_PROFILE_EXHAUSTIVE = loader('./getProfileExhaustive.graphql')
const UPDATE_CV = loader('./updateCV.graphql')
const UPDATE_EXPERIENCE = loader('./updateExperience.graphql')
const UPDATE_REFERENTS = loader('./updateReferents.graphql')
const UPDATE_EDUCATION = loader('./updateEducation.graphql')
const UPDATE_ADDITIONAL_INFORMATION = loader('./updateAdditionalInformation.graphql')
const SEND_UPLOAD_CV_FROM_EMAIL = loader('./sendUploadCVFromEmail.graphql')
const GET_PROFILE_DATE_ONET_EXPIRED = loader('./getProfileDateOnetExpired.graphql')
const UPDATE_PROFILE_CAREER = loader('./updateProfileCareer.graphql')
const GET_CAREER = loader('./getCareer.graphql')
const UPDATE_LAST_PROFILE_SECTION_CODE = loader('./updateLastProfileSectionCode.graphql')
const GET_FLOW_POSTULATION_BY_PROFILEID = loader('./getFlowPostulationByProfileId.graphql')
const GET_REGIONS_BY_COUNTRYCODE = loader('./getRegionsByCountryCode.graphql')
const GET_CITIES_BY_REGIONNAME = loader('./getCitiesByRegionName.graphql')
const GET_DISTRICTS_BY_CITYNAME = loader('./getDistrictsByCityName.graphql')
const UPDATE_CANDIDATE_PROFILE = loader('./updateCandidateProfile.graphql')
const UPDATE_PROFILE_BY_GEOLOCATION = loader('./updateProfileByGeoLocation.graphql')
const GET_LOCATIONS = loader('./getLocations.graphql')
const UPDATE_PROFILE_COORDINATES = loader('./updateProfileCoordinates.graphql')
const GET_BRANCH_OFFICES_BY_JOBID = loader('./getBranchOfficesByJobId.graphql')
const GET_ALL_BRANCH_OFFICES_BY_JOBID = loader('./getAllBranchOfficesByJobId.graphql')
const UPDATE_BRANCH_OFFICE_CANDIDATES = loader('./updateBranchOfficeCandidates.graphql')

export const useClientGetCountryCode = () => {
  const apolloClient = useApolloClient()

  const query = (variables: GetCountryCode_ProfileQueryVariables) => apolloClient.query<GetCountryCode_ProfileQuery>({
    query: GET_COUNTRY_CODE,
    variables
  })

  return [ query ]
}

export const useGetProfileExhaustive = ({ jobId }: GetProfileExhaustive_LandQueryVariables, params?: QueryHookOptions) =>
  useQuery<GetProfileExhaustive_LandQuery, GetProfileExhaustive_LandQueryVariables>(GET_PROFILE_EXHAUSTIVE, {
    ...params,
    variables: {
      jobId
    }
  })

export const useLazyGetProfileExhaustive = (variable? : GetProfileExhaustive_LandQueryVariables, params?: LazyQueryHookOptions) =>
  useLazyQuery<GetProfileExhaustive_LandQuery, GetProfileExhaustive_LandQueryVariables>(GET_PROFILE_EXHAUSTIVE, {
    ...params,
    variables: {
      jobId: variable?.jobId
    }
  })

export const useClientGetProfile = () => {
  const client = useApolloClient()
  const query = async ({ variables: { jobId } }:{ variables: { jobId:string } }) => {
    try {
      const {
        data: { getProfileExhaustive: profile }
      } = await client.query<GetProfileExhaustive_LandQuery, GetProfileExhaustive_LandQueryVariables>({
        query    : GET_PROFILE_EXHAUSTIVE,
        variables: {
          jobId
        }
      })

      return profile
    } catch (error) {
      throw error
    }
  }

  return [ query ]
}

export const useUpdateProfileBasicInformation = (params?: MutationHookOptions) =>
  useMutation<UpdateProfileBasicInformationMutation, UpdateProfileBasicInformationMutationVariables>(UPDATE_PROFILE_BASIC_INFORMATION, {
    onCompleted: params?.onCompleted,
    onError    : params?.onError })

export const useUpdateCV = (params?: MutationHookOptions) =>
  useMutation<UpdateCvMutation, UpdateCvMutationVariables>(UPDATE_CV, {
    onCompleted: params?.onCompleted,
    onError    : params?.onError })

export const useUpdateExperience = (params?: MutationHookOptions<UpdateExperienceMutation, UpdateExperienceMutationVariables>) =>
  useMutation<UpdateExperienceMutation, UpdateExperienceMutationVariables>(UPDATE_EXPERIENCE, params)

export const useUpdateReferents = (params?: MutationHookOptions) =>
  useMutation<UpdateReferentsMutation, UpdateReferentsMutationVariables>(UPDATE_REFERENTS, {
    onCompleted: params?.onCompleted,
    onError    : params?.onError })

export const useUpdateEducation = (params?: MutationHookOptions<UpdateEducationMutation, UpdateEducationMutationVariables>) =>
  useMutation<UpdateEducationMutation, UpdateEducationMutationVariables>(UPDATE_EDUCATION, params)

export const useUpdateAdditionalInformation = (params?: MutationHookOptions) =>
  useMutation<UpdateAdditionalInformationMutation, UpdateAdditionalInformationMutationVariables>(UPDATE_ADDITIONAL_INFORMATION, {
    onCompleted: params?.onCompleted,
    onError    : params?.onError })

export const useSendUploadCVFromEmail = (params?: MutationHookOptions) =>
  useMutation(SEND_UPLOAD_CV_FROM_EMAIL, params)

export const useGetProfileDateOnetExpired = () => {
  const apolloClient = useApolloClient()

  const query = (params?: { variables: GetProfileDateOnetExpiredQueryVariables }) => apolloClient.query({
    query    : GET_PROFILE_DATE_ONET_EXPIRED,
    variables: params?.variables
  })

  return [ query ]
}

export const useGetCareer = () => useQuery<GetCareerQuery>(GET_CAREER)

export const useClientGetCareer = () => {
  const client = useApolloClient()

  const query = async () => {
    const { data: { getCareer } } = await client.query<GetCareerQuery>({
      query: GET_CAREER
    })

    return getCareer
  }

  return [ query ]
}

export const useClientGetLocations = () => {
  const client = useApolloClient()

  const query = async (variables: GetLocationsQueryVariables) => {
    const { data: { getLocations } } = await client.query<GetLocationsQuery>({
      query: GET_LOCATIONS,
      variables
    })

    return getLocations
  }

  return [ query ]
}

export const useUpdateProfileCareer = (params?: MutationHookOptions<UpdateProfileCareerMutation, UpdateProfileCareerMutationVariables>) =>
  useMutation<UpdateProfileCareerMutation, UpdateProfileCareerMutationVariables>(UPDATE_PROFILE_CAREER, {
    onCompleted   : params?.onCompleted,
    onError       : params?.onError,
    refetchQueries: [
      GET_CAREER
    ]
  })

// eslint-disable-next-line max-len
export const useUpdateLastProfileSectionCode = (params?: MutationHookOptions<UpdateLastProfileSectionCodeMutation, UpdateLastProfileSectionCodeMutationVariables>) =>
  useMutation<UpdateLastProfileSectionCodeMutation, UpdateLastProfileSectionCodeMutationVariables>(UPDATE_LAST_PROFILE_SECTION_CODE, {
    onCompleted: params?.onCompleted,
    onError    : params?.onError })

export const useGetFlowPostulationByProfileId = (
  params?: QueryHookOptions<QueryGetFlowPostulationByProfileIdArgs, GetFlowPostulationByProfileIdQueryVariables>
) =>
  useQuery<QueryGetFlowPostulationByProfileIdArgs, GetFlowPostulationByProfileIdQueryVariables>(GET_FLOW_POSTULATION_BY_PROFILEID, params)

export const useLazyGetRegionsByCountryCode = (
  params?: LazyQueryHookOptions<GetRegionsByCountryCodeQuery, GetRegionsByCountryCodeQueryVariables>
) =>
  useLazyQuery<GetRegionsByCountryCodeQuery, GetRegionsByCountryCodeQueryVariables>(GET_REGIONS_BY_COUNTRYCODE, params)

export const useClientGetRegionsByCountryCode = () => {
  const client = useApolloClient()

  const query = (variables: GetRegionsByCountryCodeQueryVariables) => client.query<GetRegionsByCountryCodeQuery>({
    query: GET_REGIONS_BY_COUNTRYCODE,
    variables
  })

  return [ query ]
}

export const useLazyGetCitiesByRegionName = (
  params?: LazyQueryHookOptions<GetCitiesByRegionNameQuery, GetCitiesByRegionNameQueryVariables>
) =>
  useLazyQuery<GetCitiesByRegionNameQuery, GetCitiesByRegionNameQueryVariables>(GET_CITIES_BY_REGIONNAME, params)

export const useLazyGetDistrictsByCityName = (
  params?: LazyQueryHookOptions<GetDistrictsByCityNameQuery, GetDistrictsByCityNameQueryVariables>
) =>
  useLazyQuery<GetDistrictsByCityNameQuery, GetDistrictsByCityNameQueryVariables>(GET_DISTRICTS_BY_CITYNAME, params)

export const useClientGetDistrictsByCityName = () => {
  const client = useApolloClient()

  const query = (params: Pick<QueryOptions<GetDistrictsByCityNameQueryVariables>, 'variables' | 'fetchPolicy'>) =>
    client.query<GetDistrictsByCityNameQuery, GetDistrictsByCityNameQueryVariables>({
      query: GET_DISTRICTS_BY_CITYNAME,
      ...params
    })

  return [ query ]
}

export const useClientGetCitiesByRegionName = () => {
  const client = useApolloClient()

  const query = (variables: GetCitiesByRegionNameQueryVariables) => client.query<GetCitiesByRegionNameQuery>({
    query: GET_CITIES_BY_REGIONNAME,
    variables
  })

  return [ query ]
}

export const useUpdateCandidateProfile = (params?: MutationHookOptions<UpdateCandidateProfileMutation, UpdateCandidateProfileMutationVariables>) =>
  useMutation<UpdateCandidateProfileMutation, UpdateCandidateProfileMutationVariables>(UPDATE_CANDIDATE_PROFILE, params)

export const useUpdateProfileByGeoLocation = (
  params?: MutationHookOptions<UpdateProfileByGeoLocationMutation, UpdateProfileByGeoLocationMutationVariables>
) =>
  useMutation<UpdateProfileByGeoLocationMutation, UpdateProfileByGeoLocationMutationVariables>(UPDATE_PROFILE_BY_GEOLOCATION, params)

export const useUpdateProfileCoordinates = (
  params?: MutationHookOptions<UpdateProfileCoordinatesMutation, UpdateProfileCoordinatesMutationVariables>
) =>
  useMutation<UpdateProfileCoordinatesMutation, UpdateProfileCoordinatesMutationVariables>(UPDATE_PROFILE_COORDINATES, params)

export const useGetBranchOfficesByJobId = (params?: QueryHookOptions<GetBranchOfficesByJobIdQuery, GetBranchOfficesByJobIdQueryVariables>) =>
  useQuery<GetBranchOfficesByJobIdQuery, GetBranchOfficesByJobIdQueryVariables>(GET_BRANCH_OFFICES_BY_JOBID, params)

// eslint-disable-next-line max-len
export const useGetAllBranchOfficesByJobId = (params?: QueryHookOptions<GetAllBranchOfficesByJobIdQuery, GetAllBranchOfficesByJobIdQueryVariables>) => {
  const [
    fetchData,
    { data, loading, fetchMore }
  ] = useLazyQuery<GetAllBranchOfficesByJobIdQuery, GetAllBranchOfficesByJobIdQueryVariables>(GET_ALL_BRANCH_OFFICES_BY_JOBID, params)

  const fetchMoreCustom = ({ excludeBranchOfficeIds = [] } : { excludeBranchOfficeIds?: string[]}) => {
    const {
      getAllBranchOfficesByJobId: { info }
    }: GetAllBranchOfficesByJobIdQuery = data!

    if(!fetchMore || !info) return

    return fetchMore({
      updateQuery: (prevData, { fetchMoreResult }) =>
        produce(prevData, ({ getAllBranchOfficesByJobId: prevGetAllBranchOfficesByJobId }) => {
          const { getAllBranchOfficesByJobId } = fetchMoreResult!

          const prevBranchOfficesIds = prevGetAllBranchOfficesByJobId.docs.map(({ _id }) => _id)

          prevGetAllBranchOfficesByJobId.info = getAllBranchOfficesByJobId.info

          prevGetAllBranchOfficesByJobId.docs.push(
            ...getAllBranchOfficesByJobId.docs.filter(({ _id }) => !prevBranchOfficesIds.includes(_id))
          )
        }),
      variables: {
        excludeBranchOfficeIds,
        page: (info?.page ?? 0) + 1
      }
    })
  }

  return {
    data, fetchData, fetchMoreCustom, loading
  }
}

export const useUpdateBranchOfficeCandidates = (
  params?: MutationHookOptions<UpdateBranchOfficeCandidatesMutation, UpdateBranchOfficeCandidatesMutationVariables>
) =>
  useMutation<UpdateBranchOfficeCandidatesMutation, UpdateBranchOfficeCandidatesMutationVariables>(UPDATE_BRANCH_OFFICE_CANDIDATES,  params)
