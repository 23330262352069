import axios from 'axios'

export const getIp = async (): Promise<string> => {
  try {
    const { data: ip } = await axios.get('https://api.ipify.org')

    return ip
  } catch (error) {
    throw error
  }
}
