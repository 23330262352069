import { FC, memo, ReactNode, useEffect, useMemo, useState } from 'react'
import { matchPath } from 'react-router-dom'
import queryString from 'query-string'
import axios from 'axios'

import ThemeCustomProvider from 'layouts/Main/ThemeCustomProvider'
import { useApolloClient } from '@apollo/client'
import { VERIFY_USER } from 'hooks/useLoginRequired'
import {
  GetCandidate_Main_LayoutQuery,
  GetCandidate_Main_LayoutQueryVariables,
  VerifyUserQuery,
  VerifyUserQueryVariables
} from '__generated__/typescript-operations'
import { LOCAL_STORAGE_KEYS } from 'utils/constants'
import { GET_CANDIDATE } from 'layouts/Main/apollo/hooks'
import Loading from 'components/Loading'

interface IProps {
  children?: ReactNode;
  // any other props that come into the component
}

interface QueryStringParams {
  invitationCode: string;
  accessToken?: string;
  refreshToken?: string;
  iduser?: string;
  slug?: string;
  typeView?: string;
  trackingCandidateId?: string;
  grantFlow?: string;
  urlRedirect?: string;
}

interface Params {
  jobId?: string;
  publicationIndex?: string;
}

const {
  // REACT_APP_BASE_FRONT_URL,
  REACT_APP_ACCOUNTS_FRONT_URL,
  REACT_APP_ACCOUNTS_API_URL,
  REACT_APP_CLIENT_ID,
  REACT_APP_CLIENT_SECRET
} = process.env

const PrivateQueryParamsRoute: FC<IProps> = ({ children }) => {
  const [ loading, setLoading ] = useState(true)
  // const history = useHistory()
  const params: Params = useMemo(() => matchPath(window.location.pathname, {
    exact: true,
    path : '/:route/:jobId/publication/:publicationIndex'
  })?.params ?? {}, [])

  const queryStringParams: QueryStringParams = useMemo(() => queryString.parse(window.location.search) as any, [])

  const client = useApolloClient()

  const getMoreCredentials = async () => {
    const queryStringForm = new URLSearchParams()

    queryStringForm.append('grant_type', 'authorization_code')
    queryStringForm.append('code', `0-data-atsAppService-krowdy-${queryStringParams.invitationCode}`)
    queryStringForm.append('client_id', REACT_APP_CLIENT_ID!)
    queryStringForm.append('client_secret', REACT_APP_CLIENT_SECRET!)

    const { data: credentials } = await axios.post(`${REACT_APP_ACCOUNTS_API_URL}/api/oauth/token`, queryStringForm)

    if(!credentials) throw new Error('Error al obtener credentials')

    let fullUrlRedirect

    const commonQueryParams = {
      accessToken : credentials.accessToken,
      iduser      : credentials.userId,
      refreshToken: credentials.refreshToken
    }

    const { jobId, publicationIndex } = params

    switch (queryStringParams?.typeView) {
      case 'reminder':
        fullUrlRedirect = `${window.location.origin}/callback?${queryString.stringify({
          ...commonQueryParams,
          urlRedirect: `${window.location.origin}/job/${jobId}/publication/${publicationIndex}?${queryString.stringify({
            trackingCandidateId: queryStringParams.trackingCandidateId
          })}`
        })}`
        break
      case 'upload-cv':
        fullUrlRedirect = `${window.location.origin}/callback?${queryString.stringify({
          ...commonQueryParams,
          urlRedirect: `${window.location.origin}/job/${jobId}/publication/${publicationIndex}/upload-cv?${queryString.stringify({
            trackingCandidateId: queryStringParams.trackingCandidateId
          })}`
        })}`
        break
      default:
        fullUrlRedirect = `${window.location.origin}${window.location.pathname}?${queryString.stringify({
          ...commonQueryParams,
          invitationCode: queryStringParams.invitationCode
        })}`
        break
    }

    let constructUrlRef = `${REACT_APP_ACCOUNTS_FRONT_URL}/logout?${queryString.stringify({
      jobId      : jobId,
      ne         : 'authorization_code',
      urlRedirect: `${REACT_APP_ACCOUNTS_FRONT_URL}/callback?${queryString.stringify({
        accessToken : credentials.accessToken,
        iduser      : credentials.userId,
        jobId       : jobId,
        refreshToken: credentials.refreshToken,
        urlRedirect : fullUrlRedirect
      })}`
    })}`

    if(credentials && credentials.success === false)
      constructUrlRef = `${window.location.origin}/job/${params.jobId}/publication/${params.publicationIndex}?${queryString.stringify({
        ...(queryStringParams.typeView === 'reminder' ? { trackingCandidateId: queryStringParams?.trackingCandidateId } : {})
      })}`

    window.location.href = constructUrlRef
  }

  useEffect(() => {
    const main = async () => {
      try {
        if(queryStringParams.accessToken) {
          localStorage.setItem(LOCAL_STORAGE_KEYS.ACCESS_TOKEN, queryStringParams.accessToken)
          localStorage.setItem(LOCAL_STORAGE_KEYS.USER_ID, queryStringParams.iduser!)
          localStorage.setItem(LOCAL_STORAGE_KEYS.REFRESH_TOKEN, queryStringParams.refreshToken!)
          const { data: { getUser: user } } = await client.query<VerifyUserQuery, VerifyUserQueryVariables>({
            query: VERIFY_USER
          })

          const { data: { getCandidate } } = await client.query<GetCandidate_Main_LayoutQuery, GetCandidate_Main_LayoutQueryVariables>({
            query    : GET_CANDIDATE,
            variables: {
              jobId: params.jobId!,
              slug : queryStringParams.slug
            }
          })

          if(getCandidate.oauthSocialToken !== queryStringParams.invitationCode) {
            await getMoreCredentials()

            return
          }

          window.localStorage.setItem(LOCAL_STORAGE_KEYS.EMAIL, String(user?.email))
          window.localStorage.setItem(LOCAL_STORAGE_KEYS.FIRSTNAME, String(user?.firstName))
          window.localStorage.setItem(LOCAL_STORAGE_KEYS.LASTNAME, String(user?.lastName))
        } else {
          return await getMoreCredentials()
        }
      } catch (error) {
        // eslint-disable-next-line no-restricted-syntax
        console.log('Luis Sullca ~ file: PrivateQueryParamsRoute.tsx ~ line 156 ~ main ~ error', error)
        const errorLogin = `${REACT_APP_ACCOUNTS_FRONT_URL}/login?${queryString.stringify({
          jobId: params.jobId!
        })}`

        if(error.message === 'Forbidden: Error al autenticar')
          return await getMoreCredentials().catch(() => window.location.href = errorLogin!)

        window.location.href = errorLogin
      }

      setLoading(false)
    }

    main()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if(loading) return (
    <Loading />
  )

  return (
    <ThemeCustomProvider>
      {children}
    </ThemeCustomProvider>
  )
}

export default memo(PrivateQueryParamsRoute)

