import { Maybe } from 'graphql/jsutils/Maybe'
import { CandidateTask, GetApplyJob_LandQuery } from '__generated__/typescript-operations'
import { verifyTaskIsBlockedToEdit, allowExternalUserEditPostulation } from './flows'
import { keyBy } from './by'
import { completeStatuses, PathContentForm, incompleteStatuses } from './constants'

type Task = GetApplyJob_LandQuery['job']['stages'][0]['tasks'][0]

const orders = [
  completeStatuses,
  incompleteStatuses
]

const SortKey = {
  Equal      : 0,
  GreaterThan: 1,
  LessThan   : -1
}

const commonSortValue = (taskA: Task, taskB: Task) => {
  // -1 significa q TaskA tiene un valor menor a TaskB
  if(taskA.categoryTask.pathContentForm === PathContentForm.Curriculum) return SortKey.LessThan
  // 1 significa q TaskA tiene un valor mayor a TaskB
  if(taskB.categoryTask.pathContentForm === PathContentForm.Curriculum) return SortKey.GreaterThan

  if(taskA.categoryTask.isSchedulable) return SortKey.GreaterThan
  if(taskB.categoryTask.isSchedulable) return SortKey.LessThan

  return null
}

interface FilterAndSortTasksByCandidateTasksArgs {
  allTasks: Task[];
  candidateTasks?: Maybe<Pick<CandidateTask, '_id' | 'applyInstanceStatus' | 'taskId'>[]>;
  isScheduleTasksVisible?: Maybe<boolean>;
  filterTaskIds?: Maybe<string[]>;
  filterBlockedToEdit?: Maybe<boolean>;
}

export const filterAndSortTasksByCandidateTasks = ({
  allTasks = [],
  candidateTasks,
  isScheduleTasksVisible = false,
  filterTaskIds,
  filterBlockedToEdit = false
}: FilterAndSortTasksByCandidateTasksArgs) => {
  const taskIds = candidateTasks?.map(({ taskId }) => taskId) ?? []

  const candidateTaskBy = keyBy(candidateTasks ?? [], 'taskId')

  let sortedTasks = allTasks
    .filter(({ _id, categoryTask }) => {
      if(categoryTask.pathContentForm === PathContentForm.Curriculum) return true

      if(categoryTask.isExecutableByCall && !allowExternalUserEditPostulation()) return false

      if(candidateTasks) return taskIds.includes(String(_id))

      return true
    })

  sortedTasks = isScheduleTasksVisible ? sortedTasks : sortedTasks.filter(el => !el.categoryTask.isSchedulable)
  // filtra para seguimiento
  sortedTasks = filterTaskIds ? sortedTasks
    .filter(task => filterTaskIds.includes(task._id) || task.categoryTask.pathContentForm === PathContentForm.Curriculum) : sortedTasks

  // filtra para edicion de postulacion
  sortedTasks = filterBlockedToEdit ? sortedTasks
    .filter(task => {
      const candidateTaskA = candidateTaskBy[task._id]

      return !verifyTaskIsBlockedToEdit(task, candidateTaskA)
    }) : sortedTasks

  // ordenamiento y filtrado por defecto de applying, por estado. priorizando tareas incompletas
  sortedTasks = candidateTasks ? sortedTasks
    .filter((task) => {
      if(task.categoryTask.pathContentForm === PathContentForm.Curriculum) return true

      return candidateTaskBy[task._id]
    })
    .sort((taskA, taskB) => {
      // la tarea cv lo pone al inicio y la tarea ve lo pasa al final
      const order = commonSortValue(taskA, taskB)
      if(order) return order

      const candidateTaskA = candidateTaskBy[taskA._id]
      const candidateTaskB = candidateTaskBy[taskB._id]
      const orderIndexA = orders.findIndex((order) => order.includes(candidateTaskA.applyInstanceStatus))
      const orderIndexB = orders.findIndex((order) => order.includes(candidateTaskB.applyInstanceStatus))

      return orderIndexA - orderIndexB
    }) : sortedTasks

  return sortedTasks
}
