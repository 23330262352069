const lazyRetry = function(componentToLoadAndImport: any, componentName: any): Promise<any> {
  return new Promise((resolve, reject) => {
    const alreadyRefreshed = JSON.parse(
      window.sessionStorage.getItem(`trycomponent-${componentName}-refreshed`) || 'false'
    )
    componentToLoadAndImport().then((component: any) => {
      window.sessionStorage.setItem(`trycomponent-${componentName}-refreshed`, 'false')
      resolve(component)
    }).catch((error: any) => {
      if(!alreadyRefreshed) {
        window.sessionStorage.setItem(`trycomponent-${componentName}-refreshed`, 'true')

        return window.location.reload()
      }
      reject(error)
    })
  })
}

export {
  lazyRetry
}
