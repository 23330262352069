import { LOCAL_STORAGE_KEYS } from 'utils/constants'

export interface Credentials {
  accessToken: string;
  refreshToken: string;
  iduser: string;
}

const lsGetItem = (str: string) => window.localStorage.getItem(str) || localStorage.getItem(str)

export const getCredentials = (): Credentials => ({
  accessToken : lsGetItem(LOCAL_STORAGE_KEYS.ACCESS_TOKEN)!,
  iduser      : lsGetItem(LOCAL_STORAGE_KEYS.USER_ID)!,
  refreshToken: lsGetItem(LOCAL_STORAGE_KEYS.REFRESH_TOKEN)!
})

