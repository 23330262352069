/*
  { $   : 'USD', 'S/': 'PEN' } => {USD: "$", PEN: "S/"}
*/

export const swapObject = (obj: Record<string, string>): Record<string, string> => Object.entries(obj).reduce((ret: any, entry) => {
  const [ key, value ] = entry
  ret[ value ] = key

  return ret
}, {})

export function clean <T extends Record<string, any>, K extends keyof T>(obj: T, exceptions: K[] = []): Record<string, any> {
  const tmp = { ...obj }
  const keys = Object.keys(obj)

  for (const key of keys)
    if((tmp[key] === null || tmp[key] === undefined) && !exceptions.map(ex => ex.toString()).includes(key))
      delete tmp[key]

  return tmp
}

function cleanObjectPrivate(obj: any) {
  for (var propName in obj)
    if(obj[propName] === null || obj[propName] === undefined)
      delete obj[propName]
    else if(typeof obj[propName] === 'object')
      cleanObjectPrivate(obj[propName])
}

export const cleanObject = (obj: any) => {
  const newObject = JSON.parse(JSON.stringify(obj))

  cleanObjectPrivate(newObject)

  return newObject
}

export const completeObject = (obj: any, defaultValue: any) => {
  const tmp = { ...obj }
  const keys = Object.keys(obj)

  for (const key of keys)
    if(tmp[key] === null || tmp[key] === undefined)
      tmp[key] = defaultValue

  return tmp
}
