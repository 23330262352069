import { makeVar, useReactiveVar } from '@apollo/client'
import { Maybe } from '@graphql-tools/utils'
import { useEffect } from 'react'

export const ipVar = makeVar<Maybe<string>>(null)

export const setIp = (value: string) => {
  ipVar(value)
}

interface UseIpArgs {
  onLoad?: (ip: string) => void
}

export const useIp = ({
  onLoad
}: UseIpArgs) => {
  const ip = useReactiveVar(ipVar)

  useEffect(() => {
    if(ip && onLoad)
      onLoad(ip)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ ip ])

  return ip
}
