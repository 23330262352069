import { mongoObjectId } from './generator'
import { isParsable } from './check'
import urlParser from 'url-parse'

export enum PostMessageProtocol {
  TcpIp = 'TCP/IP',
  Udp = 'UDP'
}

export const inIframe = (): boolean => {
  try {
    return window.self !== window.top
  } catch (e) {
    return true
  }
}

const setTimeOutIds: any = []

export const postMessage = (
  iframe: HTMLIFrameElement,
  action: string,
  payload?: any,
  protocol = PostMessageProtocol.TcpIp
): Promise<any> => {
  const postMessageId = mongoObjectId()
  const { origin } = urlParser(iframe.src)

  iframe.contentWindow?.postMessage(
    JSON.stringify({
      payload,
      postMessageId,
      type: action
    }),
    origin
  )

  if(protocol === PostMessageProtocol.Udp) return Promise.resolve()

  return new Promise((resolve, reject) => {
    let hasAnError = true
    const listener: any = window.addEventListener('message', (event) => {
      // validar de q estas peticiones solo provengan de urls definidas dentro del categoryTask
      if(typeof event.data === 'string' && isParsable(event.data)) {
        const { type, payload, postMessageId: postMessageIdResp } = JSON.parse(String(event.data))

        if(type === 'ACK' && postMessageIdResp !== postMessageId) return

        if(type === action) {
          hasAnError = false

          resolve(payload)
        }

        if(type === action || type === 'ACK') {
          window.removeEventListener('message', listener)
          const timeOut = setTimeOutIds.find((element: any) => element.postMessageId === postMessageId)

          clearTimeout(timeOut.timeOutId)
          setTimeOutIds.filter((element: any) => element.postMessageId !== postMessageId)
        }
      }
    })
    const id = setTimeout(() => {
      window.removeEventListener('message', listener)
      if(hasAnError)
        reject(new Error(`PostMessage Timeout 5000 ${action}`))
      setTimeOutIds.filter((element: any) => element.postMessageId !== postMessageId)
    }, 5000)

    setTimeOutIds.push({
      postMessageId,
      timeOutId: id
    })
  })
}

