export const removeTypename = (value: any): any => {
  if(value === null || value === undefined) {
    return value
  } else if(Array.isArray(value)) {
    return value.map(v => removeTypename(v)) // check
  } else if(typeof value === 'object') {
    const newObj: any = {}
    Object.entries(value).forEach(([ key, v ]) => {
      if(key !== '__typename')
        newObj[key] = removeTypename(v)
    })

    return newObj
  }

  return value
}

export const capitalizeText = (str: string) => str ? str.charAt(0).toUpperCase() + str.substr(1).toLowerCase() : ''
