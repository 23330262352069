import { InMemoryCache, ReactiveVar, makeVar } from '@apollo/client'

import QueryResolvers from './resolvers/queries'

export const cache: InMemoryCache = new InMemoryCache({
  typePolicies: {
    ...QueryResolvers
  }
  // typePolicies: {
  //   LaborReferent: {
  //     fields: {
  //       stepSelected: {
  //         read(parent) {
  //           return parent || 0
  //         }
  //       }
  //     }
  //   },
  //   Query: {
  //     fields: {
  //       isLoggedIn: {
  //         read() {
  //           return isLoggedInVar()
  //         }
  //       }
  //     }
  //   }
  // }
})

/**
 * Set initial values when we create cache variables.
 */

export const isLoggedInVar: ReactiveVar<Boolean> = makeVar<Boolean>(false)

// Career Flow
type TypeEvent = 'Primera postulación' | 'Posterior postulación' | 'Cambio nombre de experiencia' | 'Agrego nueva experiencia'
export const eventCareerVar: ReactiveVar<TypeEvent> = makeVar<TypeEvent>('Primera postulación')
export const isChangeCareerVar: ReactiveVar<Boolean> = makeVar<Boolean>(false)
export const showCareerVar: ReactiveVar<Boolean> = makeVar<Boolean>(true)

