import mixpanel from 'mixpanel-browser'
import { LOCAL_STORAGE_KEYS } from './constants'
mixpanel.init(process.env.REACT_APP_MIXPANEL_KEY || 'e17256a42a9f80f6a9e24a2936c110a0')
const enableTracking = process.env.REACT_APP_MIXPANEL_ENABLED || 'unavailable'

function resolveUtmLastTouchProperties() {
  function extractUTMParameters() {
    const urlObj = new URL(window.location.href)
    const query = urlObj.searchParams
    const utmParameters: any = {}

    for (const [ key, value ] of query.entries()) {
      const formattedKey = key.trim().toLowerCase()
      const formattedValue = value.trim()

      if(formattedKey.startsWith('utm_')) {
        const capitalizedKey = formattedKey
          .substring(4)
          .replace(/(\b[a-z](?!\s))/g, (x) => x.toUpperCase())

        const utmKey = `UTM ${capitalizedKey} [last touch]`
        utmParameters[utmKey] = formattedValue
      }
    }

    return utmParameters
  }

  const referrer = document.referrer.toLowerCase()
  const params = extractUTMParameters()
  if(/https?:\/\/(.*)\.google\.([^\/\?]+)/i.test(referrer)) {
    if(!params['UTM Source [last touch]'])
      params['UTM Source [last touch]'] = 'Google'

    if(!params['UTM Medium [last touch]'])
      params['UTM Medium [last touch]'] = 'Organic'
  }
  if(/https?:\/\/(.*)\.yahoo\.([^\/\?]+)/i.test(referrer)) {
    if(!params['UTM Source [last touch]'])
      params['UTM Source [last touch]'] = 'Yahoo'

    if(!params['UTM Medium [last touch]'])
      params['UTM Medium [last touch]'] = 'Organic'
  }
  if(/https?:\/\/(.*)\.bing\.([^\/\?]+)/i.test(referrer)) {
    if(!params['UTM Source [last touch]'])
      params['UTM Source [last touch]'] = 'Bing'

    if(!params['UTM Medium [last touch]'])
      params['UTM Medium [last touch]'] = 'Organic'
  }
  if(/https?:\/\/(.*)\.laborum\.pe([^\/\?]+)/i.test(referrer)) {
    if(!params['UTM Source [last touch]'])
      params['UTM Source [last touch]'] = 'Laborum'

    if(!params['UTM Medium [last touch]'])
      params['UTM Medium [last touch]'] = 'Organic'
  }
  if(/https?:\/\/(.*)\.facebook\.([^\/\?]+)/i.test(referrer)) {
    if(!params['UTM Source [last touch]'])
      params['UTM Source [last touch]'] = 'Facebook'

    if(!params['UTM Medium [last touch]'])
      params['UTM Medium [last touch]'] = 'Social'
  }
  if(
    /https?:\/\/(.*)\.linkedin\.([^\/\?]+)/i.test(referrer) ||
    /https?:\/\/(.*)\.lnkd\.([^\/\?]+)/i.test(referrer)
  ) {
    if(!params['UTM Source [last touch]'])
      params['UTM Source [last touch]'] = 'LinkedIn'

    if(!params['UTM Medium [last touch]'])
      params['UTM Medium [last touch]'] = 'Social'
  }
  if(/https?:\/\/(.*)\.krowdy\.([^\/\?]+)/i.test(referrer)) {
    if(!params['UTM Source [last touch]'])
      params['UTM Source [last touch]'] = 'Krowdy'

    if(!params['UTM Medium [last touch]'])
      params['UTM Medium [last touch]'] = 'Organic'
  }

  if(!params['UTM Source [last touch]'])
    params['UTM Source [last touch]'] = 'Google'

  if(!params['UTM Medium [last touch]'])
    params['UTM Medium [last touch]'] = 'Organic'

  return params
}

if(enableTracking === 'available') {
  const params = resolveUtmLastTouchProperties()

  mixpanel.register(params)
}

class MixpanelEvent {
  async track(eventName: string, data: any) {
    try {
      const dataToTrack = await this.validateSessionAndPrepareDataToTrack(data)

      mixpanel.track(eventName, dataToTrack)
    } catch (error) {
      // eslint-disable-next-line no-restricted-syntax
      console.log('🚀 ~ v2_clickAtSendProfile ~ error', error)
    }
  }

  async v2_clickAtSendProfile(data: any) {
    try {
      const dataToTrack = await this.validateSessionAndPrepareDataToTrack(data)

      mixpanel.track('Click en enviar perfil', dataToTrack)
    } catch (error) {
      // eslint-disable-next-line no-restricted-syntax
      console.log('🚀 ~ v2_clickAtSendProfile ~ error', error)
    }
  }

  async v2_editSection(data: any) {
    try {
      const dataToTrack = await this.validateSessionAndPrepareDataToTrack(data)

      mixpanel.track('Editó sección en App de postulación', dataToTrack)
    } catch (error) {
      // eslint-disable-next-line no-restricted-syntax
      console.log('🚀 ~ v2_clickAtSendProfile ~ error', error)
    }
  }

  async v2_cvConfirm(data: any) {
    try {
      const dataToTrack = await this.validateSessionAndPrepareDataToTrack(data)

      mixpanel.track('Confirmó CV en App de postulación', dataToTrack)
    } catch (error) {
      // eslint-disable-next-line no-restricted-syntax
      console.log('🚀 ~ v2_clickAtSendProfile ~ error', error)
    }
  }

  async v2_leavePostulation(data: any) {
    try {
      const dataToTrack = await this.validateSessionAndPrepareDataToTrack(data)

      mixpanel.track('Click en retirar postulación', dataToTrack)
    } catch (error) {
      // eslint-disable-next-line no-restricted-syntax
      console.log('🚀 ~ v2_clickAtSendProfile ~ error', error)
    }
  }

  async v2_useSimilarJobs(data: any) {
    try {
      const dataToTrack = await this.validateSessionAndPrepareDataToTrack(data)

      mixpanel.track('Click en buscar empleos similares', dataToTrack)
    } catch (error) {
      // eslint-disable-next-line no-restricted-syntax
      console.log('🚀 ~ v2_useSimilarJobs ~ error', error)
    }
  }

  v2_clickAtBasicInformation(data: any) {
    try {
      const dataToTrack = this.validateSessionAndPrepareDataToTrack(data)

      mixpanel.track('Ingresó a Información básica', dataToTrack)
    } catch (error) {
      // eslint-disable-next-line no-restricted-syntax
      console.log('🚀 ~ v2_clickAtSendProfile ~ error', error)
    }
  }

  async validateSession(userData: any) {
    try {
      const session = localStorage.getItem(LOCAL_STORAGE_KEYS.MIXPANEL_SESSION)
      if(session === 'available') return

      await this.v2_identify(userData)
    } catch (error) {
      // eslint-disable-next-line no-restricted-syntax
      console.log('🚀 ~ validateSession ~ error', error)
    }
  }

  async v2_identify(userData?: any) {
    try {
      if(enableTracking !== 'available') return
      if(!userData._id) return

      mixpanel.identify(userData._id)
      await this.registerUserOnMixpanel(userData)
      window.localStorage.setItem(LOCAL_STORAGE_KEYS.MIXPANEL_SESSION, 'available')
    } catch (error) {
      // eslint-disable-next-line no-restricted-syntax
      console.log('🚀 ~ v2_identify ~ error', error)
    }
  }

  formatUserDataToMixpanel(userData: any) {
    const userInfoToRegister = {
      Apellido             : userData?.lastName || '',
      Email                : userData?.email,
      Nombre               : userData?.firstName,
      'Nombres y Apellidos': userData?.fullName || `${userData.firstName} ${userData.lastName || ''}`,
      ...(userData.phone ? { Celular: userData?.phone } : {})
    }
    const userInfoToSet = {
      $email: userData?.email,
      name  : userData?.fullName || `${userData?.firstName} ${userData?.lastName || ''}`,
      ...(userData.phone ? { $phone: userData?.phone } : {})
    }

    return {
      userInfoToRegister,
      userInfoToSet
    }
  }

  async registerUserOnMixpanel(userData: any) {
    try {
      const { userInfoToRegister, userInfoToSet } = this.formatUserDataToMixpanel(userData)

      mixpanel.register(userInfoToRegister)
      mixpanel.people.set(userInfoToSet)
    } catch (error) {
      // eslint-disable-next-line no-restricted-syntax
      console.log('🚀 ~ registerUserOnMixpanel ~ error', error)
    }
  }

  validateSessionSync(userData: any) {
    try {
      const session = localStorage.getItem(LOCAL_STORAGE_KEYS.MIXPANEL_SESSION)
      if(session === 'available') return

      this.v2_identifySync(userData)
    } catch (error) {
      // eslint-disable-next-line no-restricted-syntax
      console.log('🚀 ~ validateSession ~ error', error)
    }
  }

  v2_identifySync(userData?: any) {
    try {
      if(enableTracking !== 'available') return
      if(!userData._id) return

      mixpanel.identify(userData._id)
      this.registerUserOnMixpanelSync(userData)
      window.localStorage.setItem(LOCAL_STORAGE_KEYS.MIXPANEL_SESSION, 'available')
    } catch (error) {
      // eslint-disable-next-line no-restricted-syntax
      console.log('🚀 ~ v2_identify ~ error', error)
    }
  }

  registerUserOnMixpanelSync(userData: any) {
    try {
      let userInfoToRegister = {
        Apellido             : userData?.lastName || '',
        Email                : userData?.email,
        Nombre               : userData?.firstName,
        'Nombres y Apellidos': userData?.fullName || `${userData.firstName} ${userData.lastName || ''}`,
        ...(userData.phone ? { Celular: userData?.phone } : {})
      }
      mixpanel.register(userInfoToRegister)
      let userInfoToSet = {
        $email: userData?.email,
        name  : userData?.fullName || `${userData?.firstName} ${userData?.lastName || ''}`,
        ...(userData.phone ? { $phone: userData?.phone } : {})
      }
      mixpanel.people.set(userInfoToSet)
    } catch (error) {
      // eslint-disable-next-line no-restricted-syntax
      console.log('🚀 ~ registerUserOnMixpanel ~ error', error)
    }
  }

  async career_selectedAtCareer(data: any) {
    try {
      const dataToTrack = await this.validateSessionAndPrepareDataToTrack(data)

      mixpanel.track('Candidato selecciona plantilla', dataToTrack)
    } catch (error) {
      // eslint-disable-next-line no-restricted-syntax
      console.log('🚀 ~ career_selectedAtCareer ~ error', error)
    }
  }

  async career_enterToPage(data: any) {
    try {
      const dataToTrack = await this.validateSessionAndPrepareDataToTrack(data)

      mixpanel.track('Candidato ingresa a una página', dataToTrack)
    } catch (error) {
      // eslint-disable-next-line no-restricted-syntax
      console.log('🚀 ~ v2_userEnterToApp ~ error', error)
    }
  }

  async geolocation_enterToPage(data: any) {
    try {
      const dataToTrack = await this.validateSessionAndPrepareDataToTrack(data)

      mixpanel.track('Candidato ingresa a vista de geolocalización', dataToTrack)
    } catch (error) {
      // eslint-disable-next-line no-restricted-syntax
      console.log('🚀 ~ geolocation_enterToPage ~ error', error)
    }
  }

  async geolocation_clickAtEnableLocation(data: any) {
    try {
      const dataToTrack = await this.validateSessionAndPrepareDataToTrack(data)

      mixpanel.track('Candidato intenta habilitar ubicación', dataToTrack)
    } catch (error) {
      // eslint-disable-next-line no-restricted-syntax
      console.log('🚀 ~ geolocation_clickAtEnableLocation ~ error', error)
    }
  }

  async geolocation_leavePage(data: any) {
    try {
      const dataToTrack = await this.validateSessionAndPrepareDataToTrack(data)

      mixpanel.track('Candidato abandona vista de geolocalización', dataToTrack)
    } catch (error) {
      // eslint-disable-next-line no-restricted-syntax
      console.log('🚀 ~ geolocation_leavePage ~ error', error)
    }
  }

  async validateSessionAndPrepareDataToTrack(data: any) {
    try {
      if(enableTracking !== 'available') return

      await this.validateSession(data.userData)

      // remove userData because mixpanel.track() doesn't support nested JSON
      delete data.userData

      return data
    } catch (error) {
      // eslint-disable-next-line no-restricted-syntax
      console.log('🚀 ~ validateSessionAndPrepareDataToTrack ~ error', error)
    }
  }
}

const mixPanelService = new MixpanelEvent()

export {
  mixPanelService
}
