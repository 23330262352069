import { MutationHookOptions, OperationVariables, QueryHookOptions, QueryOptions, useApolloClient, useLazyQuery, useMutation, useQuery } from '@apollo/client'
import { loader } from 'graphql.macro'
import {
  GetApplyJob_LandQuery,
  GetApplyJob_LandQueryVariables,
  GetTheme_LandQuery,
  GetTheme_LandQueryVariables,
  SendProfile_LandMutation,
  SendProfile_LandMutationVariables,
  CreateResultTask_LandMutation,
  CreateResultTask_LandMutationVariables,
  GetCandidateTask_LandQuery,
  GetCandidateTask_LandQueryVariables,
  GetAppSections_LandQuery,
  GetAppSections_LandQueryVariables,
  NotifyOpenTaskInDesktop_LandMutation,
  NotifyOpenTaskInDesktop_LandMutationVariables,
  GetSimilarJobs_CongratulationsQuery,
  GetSimilarJobs_CongratulationsQueryVariables,
  MoveLastManualStageId_LandMutation,
  MoveLastManualStageId_LandMutationVariables,
  ValidateCvMutation,
  ValidateCvMutationVariables,
  NotifyAdminsCandidateCompletedFirstStageMutation,
  NotifyAdminsCandidateCompletedFirstStageMutationVariables,
  UpdateCandidatePhonesMutation,
  UpdateCandidatePhonesMutationVariables,
  GetCandidate_InfoQuery,
  GetCandidate_InfoQueryVariables,
  GetCandidateByEmailExactQueryVariables,
  GetCandidateByEmailExactQuery,
  UpdateCandidateMutation,
  UpdateCandidateMutationVariables,
  SendEditPostulationMutation,
  SendEditPostulationMutationVariables
} from '__generated__/typescript-operations'

export const GET_JOB = loader('../getJob.graphql')
export const GET_THEME = loader('../getTheme.graphql')
export const SEND_PROFILE = loader('../sendProfile.graphql')
export const CREATE_RESULT_TASK = loader('../createResultTask.graphql')
export const GET_CANDIDATE_TASK = loader('../getCandidateTask.graphql')
export const GET_APP_SECTIONS = loader('../getAppSections.graphql')
export const NOTIFY_OPEN_TASK_IN_DESKTOP = loader('../notifyOpenTaskInDesktop.graphql')
export const GET_SIMILAR_JOBS = loader('../getSimilarJobs.graphql')
export const NOTIFY_MULTIPLE_FLOW = loader('../notifyMultipleFlowInterview.graphql')
export const MOVE_LAST_MANUAL_STAGE_ID = loader('../moveLastManualStageId.graphql')
export const VALIDATE_CV = loader('../validateCv.graphql')
export const NOTIFY_ADMINS_CANDIDATE_COMPLETED_FIRST_STAGE = loader('../notifyAdminsCandidateCompletedFirstStage.graphql')
export const UPDATE_CANDIDATE_PHONES = loader('../updateCandidatePhones.graphql')
export const GET_CANDIDATE_INFO = loader('../getCandidateInfo.graphql')
export const VERIFY_EXISTS_CANDIDATE_BY_EMAIL = loader('../getCandidateByEmailExact.graphql')
export const UPDATE_CANDIDATE = loader('../updateCandidate.graphql')
export const SEND_EDIT_POSTULATION = loader('../sendEditPostulation.graphql')

export const useGetJob = (variables: GetApplyJob_LandQueryVariables) =>
  useQuery<GetApplyJob_LandQuery, GetApplyJob_LandQueryVariables>(GET_JOB, {
    variables
  })

export const useGetTheme = (variables: GetTheme_LandQueryVariables) =>
  useQuery<GetTheme_LandQuery, GetTheme_LandQueryVariables>(GET_THEME, {
    variables
  })

export const useSendProfile = () =>
  useMutation<SendProfile_LandMutation, SendProfile_LandMutationVariables>(SEND_PROFILE)

export const useCreateResultTask = () =>
  useMutation<CreateResultTask_LandMutation, CreateResultTask_LandMutationVariables>(CREATE_RESULT_TASK)

export const useClientGetCandidateTask = () => {
  const client = useApolloClient()

  const query = (variables: GetCandidateTask_LandQueryVariables) => client.query<GetCandidateTask_LandQuery>({
    fetchPolicy: 'network-only',
    query      : GET_CANDIDATE_TASK,
    variables
  })

  return [ query ]
}

export const useClientGetAppSections = () => {
  const client = useApolloClient()

  const query = (variables: GetAppSections_LandQueryVariables) => client.query<GetAppSections_LandQuery>({
    fetchPolicy: 'network-only',
    query      : GET_APP_SECTIONS,
    variables
  })

  return [ query ]
}

export const useNotifyOpenTaskInDesktop = () =>
  useMutation<NotifyOpenTaskInDesktop_LandMutation, NotifyOpenTaskInDesktop_LandMutationVariables>(NOTIFY_OPEN_TASK_IN_DESKTOP)

export const useGetSimilarJobs = (variables: GetSimilarJobs_CongratulationsQueryVariables) =>
  useQuery<GetSimilarJobs_CongratulationsQuery, GetSimilarJobs_CongratulationsQueryVariables>(GET_SIMILAR_JOBS, {
    variables
  })

export const useClientGetSimilarJobs = () => {
  const client = useApolloClient()

  const query = (variables: GetSimilarJobs_CongratulationsQueryVariables) => client.query<GetSimilarJobs_CongratulationsQuery>({
    fetchPolicy: 'network-only',
    query      : GET_SIMILAR_JOBS,
    variables
  })

  return [ query ]
}

export const useClientGetCandidateByEmailExact = () => {
  const client = useApolloClient()

  const query = (params: Pick<QueryOptions<GetCandidateByEmailExactQueryVariables>, 'variables' | 'fetchPolicy'>) =>
    client.query<GetCandidateByEmailExactQuery, GetCandidateByEmailExactQueryVariables>({
      fetchPolicy: params.fetchPolicy,
      query      : VERIFY_EXISTS_CANDIDATE_BY_EMAIL,
      variables  : params.variables
    })

  return [ query ]
}

export const useLazyGetCandidateByEmailExact = (params?: QueryHookOptions<GetCandidateByEmailExactQuery, GetCandidateByEmailExactQueryVariables>) =>
  useLazyQuery(VERIFY_EXISTS_CANDIDATE_BY_EMAIL, params)

export const useNotifyMultipleFlowInterview = (params?: MutationHookOptions) =>
  useMutation(NOTIFY_MULTIPLE_FLOW, params)

export const useMoveLastManualStageId = (
  params?: MutationHookOptions<MoveLastManualStageId_LandMutation, MoveLastManualStageId_LandMutationVariables>
) =>
  useMutation<MoveLastManualStageId_LandMutation, MoveLastManualStageId_LandMutationVariables>(MOVE_LAST_MANUAL_STAGE_ID, params)

export const useNotifyAdminsCandidateCompletedFirstStage = (
  params?: MutationHookOptions<NotifyAdminsCandidateCompletedFirstStageMutation, NotifyAdminsCandidateCompletedFirstStageMutationVariables>
) =>
  useMutation<NotifyAdminsCandidateCompletedFirstStageMutation, NotifyAdminsCandidateCompletedFirstStageMutationVariables>(
    NOTIFY_ADMINS_CANDIDATE_COMPLETED_FIRST_STAGE,
    params
  )

export const useVerifyCv = (params?:MutationHookOptions<ValidateCvMutation, ValidateCvMutationVariables>) =>
  useMutation(VALIDATE_CV, params as OperationVariables)

export const useUpdateCandidatePhones = () =>
  useMutation<UpdateCandidatePhonesMutation, UpdateCandidatePhonesMutationVariables>(UPDATE_CANDIDATE_PHONES)

export const useGetCandidateInfo = (params?: QueryHookOptions<GetCandidate_InfoQuery, GetCandidate_InfoQueryVariables>) =>
  useQuery<GetCandidate_InfoQuery, GetCandidate_InfoQueryVariables>(GET_CANDIDATE_INFO, params)

export const useUpdateCandidate = (params?:MutationHookOptions<UpdateCandidateMutation, UpdateCandidateMutationVariables>) =>
  useMutation(UPDATE_CANDIDATE, params as OperationVariables)

export const useSendEditPostulation = (params?:MutationHookOptions<SendEditPostulationMutation, SendEditPostulationMutationVariables>) =>
  useMutation(SEND_EDIT_POSTULATION, params as OperationVariables)
