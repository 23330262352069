import { FC, useState, memo } from 'react'
import { VpnKey as VpnKeyIcon, Close as CloseIcon } from '@material-ui/icons'
import { useSnackbar } from 'notistack'

import { PaletteColor, Theme } from 'utils/theme'

import { ValidateExecuterPopupProps } from 'interfaces/pages/_lobby/ValidateExecuterPop'

import { useClientVerifyKey } from './apollo/hooks'
import { Button, makeStyles, TextField, Typography, Card, CardContent, Dialog, CardHeader } from '@krowdy/kds-core'

const ValidateExecuterPopup: FC<ValidateExecuterPopupProps> = ({
  open,
  onClose,
  taskId,
  onSubmit
}) => {
  const classes = useStyles()
  // const dispatch = useDispatch()
  const [ code, setCode ] = useState('')
  const [ verifyKey ] = useClientVerifyKey()
  const { enqueueSnackbar } = useSnackbar()

  const _handleText = ({ target:{ value } }: any) => {
    setCode(value)
  }

  const _handleKeyDown = async ({ key }: any) => {
    if(key === 'Enter') await _handleSubmit()
  }

  const _handleSubmit = async () => {
    try {
      if(!code.trim()) return

      const result = await verifyKey({
        responsableKey: code,
        taskId
      })
      if(!result.success) return enqueueSnackbar('Código de acceso incorrecto', { variant: 'error' })

      if(onSubmit) onSubmit(Boolean(result.success))
    } catch (error) {
      throw error
    }
  }

  return (
    <Dialog
      onClose={onClose}
      open={open}>
      <Card>
        <CardHeader action={<CloseIcon className={classes.closeButton} onClick={onClose} />} className={classes.header} title='¡Hola Entrevistador!' />
        <CardContent className={classes.contentAccess} >
          <Typography variant='body3'>
              No hemos podido reconocer tu usuario. Si tienes el código de acceso como entrevistador ingrésalo aquí:
          </Typography>
          <TextField
            // classes={{ input: classes.input }}
            className={classes.mgt}
            fullWidth
            InputProps={{
              startAdornment: <VpnKeyIcon className={classes.mgrS} color='primary' />
            }}
            onChange={_handleText}
            onKeyDown={_handleKeyDown}
            placeholder='Código de acceso'
            size='small'
            value={code}
            variant='outlined' />
          <Button
            className={classes.mgt}
            color='primary'
            onClick={_handleSubmit}
            variant='contained' >Acceder</Button>
        </CardContent>
      </Card>
    </Dialog>
  )
}
const useStyles = makeStyles<Theme>((theme) => ({
  closeButton: {
    cursor: 'pointer'
  },
  content: {
    color: theme.palette.secondary[10]
  },
  contentAccess: {
    alignItems   : 'center',
    display      : 'flex',
    flexDirection: 'column',
    maxWidth     : 340
  },
  header: {
    color    : (theme.palette.primary as PaletteColor)[900],
    textAlign: 'center'
  },
  input: {
    marginLeft: theme.spacing(1)
  },
  isReviewer: {
    display      : 'flex',
    flexDirection: 'row'
  },
  keyInput: {
    border      : `1px solid ${theme.palette.secondary[100]}`,
    borderRadius: 4,
    margin      : theme.spacing(2.5, 0),
    padding     : theme.spacing(0, 1)
  },
  margins: {
    marginTop: theme.spacing(2.5)
  },
  mgrS: {
    marginRight: theme.spacing(1)
  },
  mgt: {
    marginTop: theme.spacing(1.5)
  }
}), { name: 'ValidateExecuterPopup' })

export default memo(ValidateExecuterPopup)
