import { matchPath } from 'react-router-dom'
import { CandidateTask, Maybe, Task } from '__generated__/typescript-operations'
import { ParentSourceOrigin, PathContentForm } from './constants'
import { getSearchParams, Location } from './getQueryParams'
import { inIframe } from './iframe'

export const isCandidatePublicView = () => {
  const pathname = '/job/:jobId/publication/:publicationIndex/candidate/:candidateId'
  const match = matchPath(window.location.pathname, {
    exact: true,
    path : pathname
  })

  return Boolean(match)
}

export const verifyAllowPublicAccess = () => isCandidatePublicView() && !inIframe()

export const allowExternalUserEditPostulation = () => {
  const qsParams = getSearchParams<{ userType: string; }>(window.location)

  return isCandidatePublicView() && inIframe() && qsParams.userType !== 'candidate'
}

export const validEditPostulation = (_location?: Location) => {
  const qsParams = getSearchParams<{ origin: ParentSourceOrigin; }>(_location)

  return allowExternalUserEditPostulation() && qsParams.origin === ParentSourceOrigin.Gats
}

export const getLandPathname = () => {
  const needCandidateAccessToken = isCandidatePublicView()

  return needCandidateAccessToken ? '/job/:jobId/publication/:publicationIndex/candidate/:candidateId' : '/job/:jobId/publication/:publicationIndex'
}

export const verifyTaskIsBlockedToEdit = (task: Pick<Task, 'categoryTask'>, candidateTask?: Maybe<Pick<CandidateTask, '_id'>>) => {
  const isFlowEditPostulation = validEditPostulation()

  const blocked = (
    !task.categoryTask.canBeEditPostulation ||
    (task.categoryTask.pathContentForm !== PathContentForm.Curriculum && !candidateTask)
  )

  return isFlowEditPostulation && blocked
}

