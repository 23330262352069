import { FC, ReactNode } from 'react'
import { useLoginRequired } from 'hooks/useLoginRequired'
import Loading from 'components/Loading'
import useSearchParams from 'hooks/useSearchParams'

interface IProps {
  children?: ReactNode;
  // any other props that come into the component
}

const PrivateRoute: FC<IProps> = ({ children }) => {
  const qsParams = useSearchParams()

  const data = useLoginRequired({
    skip: Boolean(qsParams.alloweditpostulation)
  })

  if(data.loading) return (<Loading />)

  if(!children) return null

  return <>{children}</>
}

export default PrivateRoute

