import { FC, useMemo } from 'react'
import dayjs from 'dayjs'
import clsx from 'clsx'
import { Person as PersonIcon } from '@material-ui/icons'
import { Avatar, krowdyTheme, ListItem, ListItemAvatar, ListItemSecondaryAction, ListItemText, makeStyles, Typography } from '@krowdy/kds-core'

import { CandidateTaskStatus } from '__generated__/typescript-operations'
import { ScheduleCardProps, StyleProps } from 'interfaces/pages/_lobby/CandidatesLobby/ScheduleCard'

import { PaletteColor } from 'utils/theme'

const ScheduleCard: FC<ScheduleCardProps> = ({
  onClick,
  candidate = { email: '', firstName: '', lastName: '', photo: '' },
  taskDate,
  isActive,
  isSelected,
  status
}) => {
  const isExecuted = useMemo(() => [ CandidateTaskStatus.Executed, CandidateTaskStatus.Reviewed ].includes(status), [ status ])

  const classes = useStyles({ isExecuted })

  const initials =  useMemo(() =>
    `${candidate?.firstName ? candidate.firstName[0] : ''} ${candidate?.lastName ? candidate.lastName[0] : ''}`
  , [ candidate?.firstName, candidate?.lastName ])

  const _handleClickItem = () => {
    if(!isExecuted && onClick)
      onClick()
  }

  return (
    <ListItem
      classes={{
        container: clsx(classes.item, {
          [classes.selected]: isSelected,
          [classes.executed]: isExecuted
        })
      }}
      className={classes.colorText}
      disabled={isExecuted}
      onClick={_handleClickItem}>
      <ListItemAvatar>
        <Avatar alt={initials} src={candidate?.photo ?? ''}>
          {initials}
        </Avatar>
      </ListItemAvatar>
      <ListItemText
        primary={
          <Typography variant='h6'>
            { candidate?.firstName }
          </Typography>
        }
        secondary={
          <Typography
            className={classes.colorText}
            variant='body1'>
            {candidate?.email}
          </Typography>
        } />
      <ListItemSecondaryAction className={classes.colorText}>
        { isExecuted ? 'Realizada' : taskDate ? dayjs(taskDate).format('hh:mm tt') : 'Sin agenda' }
        { (taskDate && isActive) ?  <PersonIcon color='primary' /> : null }
      </ListItemSecondaryAction>
    </ListItem>
  )
}

const useStyles = makeStyles((theme) => ({
  colorText: {
    color  : theme.palette.grey[100],
    padding: theme.spacing(0)
  },
  executed: {
    background: (krowdyTheme.palette as any).secondary[700],
    color     : theme.palette.grey[600],
    cursor    : 'default'
  },
  item: {
    '&:hover': {
      background: ({ isExecuted }: StyleProps) =>
        isExecuted ? (krowdyTheme.palette as any).secondary[700] : (theme.palette.primary as PaletteColor)[800]
    },
    borderRadius: theme.spacing(0.5),
    cursor      : ({ isExecuted }: StyleProps) =>
      isExecuted ? 'default' : 'pointer',
    listStyle: 'none',
    padding  : theme.spacing(0, 1),
    width    : '100%'
  },
  selected: {
    background  : (theme.palette.primary as PaletteColor)[800],
    borderRadius: theme.spacing(0.5)
  }
}), { name: 'ScheduleCard' })

export default ScheduleCard
