import { useHistory, generatePath, useParams, matchPath } from 'react-router'
import { ApolloError, LazyQueryHookOptions, QueryHookOptions, useApolloClient, useLazyQuery, useMutation, useQuery } from '@apollo/client'
import { loader } from 'graphql.macro'
// import { useSnackbar } from 'notistack'
import qs from 'query-string'
import {
  GetApplyJob_Main_LayoutQuery,
  GetApplyJob_Main_LayoutQueryVariables,
  GetTheme_Main_LayoutQuery,
  GetTheme_Main_LayoutQueryVariables,
  GetCandidate_Main_LayoutQuery,
  GetCandidate_Main_LayoutQueryVariables,
  SendPostulationReminderMailMutation,
  SendPostulationReminderMailMutationVariables,
  UpdateProfileValidationMutation,
  UpdateProfileValidationMutationVariables,
  CandidateFragmentFragment,
  JobStagesFragmentFragment
} from '__generated__/typescript-operations'
import { omit } from 'utils/index'

export const GET_JOB = loader('./getJob.graphql')
const GET_THEME = loader('./getTheme.graphql')
export const GET_CANDIDATE = loader('./getCandidate.graphql')
const SEND_REMINDER_EMAIL = loader('./sendReminderEmail.graphql')
const UPDATE_PROFILE_VALIDATION = loader('./updateProfileValidation.graphql')
export const JOB_STAGES_FRAGMENT = loader('./jobStagesFragment.graphql')
export const CANDIDATE_FRAGMENT = loader('./candidateFragment.graphql')

export const useGetJob = (params: QueryHookOptions<GetApplyJob_Main_LayoutQuery, GetApplyJob_Main_LayoutQueryVariables>) =>
  useQuery<GetApplyJob_Main_LayoutQuery, GetApplyJob_Main_LayoutQueryVariables>(GET_JOB, params)

export const useClientGetJob = () => {
  const client = useApolloClient()
  const query = async ({ variables: { jobId, publicationIndex } }:{ variables: { jobId:string, publicationIndex:number } }) => {
    try {
      const {
        data: { job }
      } = await client.query<GetApplyJob_Main_LayoutQuery, GetApplyJob_Main_LayoutQueryVariables>({
        query    : GET_JOB,
        variables: {
          jobId,
          publicationIndex
        }
      })

      return job
    } catch (error) {
      throw error
    }
  }

  return [ query ]
}

export const useLazyGetJob = (
  params?: LazyQueryHookOptions<GetApplyJob_Main_LayoutQuery, GetApplyJob_Main_LayoutQueryVariables>
) => useLazyQuery<GetApplyJob_Main_LayoutQuery, GetApplyJob_Main_LayoutQueryVariables>(GET_JOB, params)

export const useGetTheme = (variables: GetTheme_Main_LayoutQueryVariables) =>
  useQuery<GetTheme_Main_LayoutQuery, GetTheme_Main_LayoutQueryVariables>(GET_THEME, {
    variables
  })

const useHandleErrorGetCandidate = () => {
  const history = useHistory()
  const params = useParams<{ jobId: string; publicationIndex: string; }>()

  const handleError = (error: ApolloError) => {
    const queryStringParams = qs.parse(window.location.search) as any

    // eslint-disable-next-line no-restricted-syntax
    console.log('error.message', error.message)

    if(
      error.message.includes('Este aviso se encuentra inactivo'))
      history.push({
        pathname: generatePath('/unavailable')
      })

    if(
      error.message.includes('aceptaba postulaciones'))
      history.push({
        pathname: generatePath('/unavailable')
      })

    if(error.message.includes('candidato le pertenece a otro usuario')) {
      const newUrl = qs.stringifyUrl({
        query: omit(qs.parse(window.location.search), [ 'trackingCandidateId' ]),
        url  : `${window.location.origin}${window.location.pathname}`
      })

      window.location.href = newUrl

      return
    }
    // snackbarController.enqueueSnackbar(error.message)
    if(
      error.message.includes('Este candidato pertenece a') &&
      queryStringParams.trackingCandidateId &&
      !matchPath(window.location.pathname, {
        exact: true,
        path : '/merge/:jobId/publication/:publicationIndex'
      })) {
      const newUrl = qs.stringifyUrl({
        query: qs.parse(window.location.search),
        url  : `${window.location.origin}${generatePath('/merge/:jobId/publication/:publicationIndex', {
          jobId           : params.jobId,
          publicationIndex: params.publicationIndex
        })}`
      })

      window.location.href = newUrl
    }
  }

  return [ handleError ]
}

export const useGetCandidate = (variables: GetCandidate_Main_LayoutQueryVariables) => {
  const [ handleError ] = useHandleErrorGetCandidate()

  return useQuery<GetCandidate_Main_LayoutQuery, GetCandidate_Main_LayoutQueryVariables>(GET_CANDIDATE, {
    onError: handleError,
    variables
  })
}

export const useLazyGetCandidate = (
  params?: LazyQueryHookOptions<GetCandidate_Main_LayoutQuery, GetCandidate_Main_LayoutQueryVariables>
) => {
  const [ handleError ] = useHandleErrorGetCandidate()

  return useLazyQuery<GetCandidate_Main_LayoutQuery, GetCandidate_Main_LayoutQueryVariables>(GET_CANDIDATE, {
    onError: handleError,
    ...params
  })
}

export const useClientGetCandidate = () => {
  const client = useApolloClient()

  const query = (variables: GetCandidate_Main_LayoutQueryVariables) => client.query<GetCandidate_Main_LayoutQuery>({
    fetchPolicy: 'network-only',
    query      : GET_CANDIDATE,
    variables
  })

  return [ query ]
}

export const useGetCandidateFragment = () => {
  const client = useApolloClient()

  const getFragment = (candidateId: string) => {
    const candidateCache = client.readFragment<CandidateFragmentFragment>({
      fragment: CANDIDATE_FRAGMENT,
      id      : `Candidate:${candidateId}`
    })

    return candidateCache
  }

  return [ getFragment ]
}

export const useGetJobStageFragment = () => {
  const client = useApolloClient()

  const getFragment = (jobId: string) => {
    const jobCache = client.readFragment<JobStagesFragmentFragment>({
      fragment: JOB_STAGES_FRAGMENT,
      id      : `Job:${jobId}`
    })

    return jobCache
  }

  return [ getFragment ]
}

export const useUpdateDateReminderEmail = (candidateId: string) =>
  useMutation<SendPostulationReminderMailMutation, SendPostulationReminderMailMutationVariables>(SEND_REMINDER_EMAIL, {
    variables: {
      candidateId
    }
  })

export const useClientGetCandidateCache = () => {
  const client = useApolloClient()
  const query = async ({ variables: { jobId, slug, trackingCandidateId } }
      : { variables: { jobId:string; slug:string; trackingCandidateId: string; } }) => {
    try {
      const {
        data: { getCandidate: candidate }
      } = await client.query<GetCandidate_Main_LayoutQuery, GetCandidate_Main_LayoutQueryVariables>({
        query    : GET_CANDIDATE,
        variables: {
          jobId,
          slug,
          trackingCandidateId
        }
      })

      return candidate
    } catch (error) {
      throw error
    }
  }

  return [ query ]
}

export const useUpdateProfileValidation = (candidateId: string) =>
  useMutation<UpdateProfileValidationMutation, UpdateProfileValidationMutationVariables>(UPDATE_PROFILE_VALIDATION, {
    variables: {
      candidateId
    }
  })
