import { MutationHookOptions, OperationVariables, useMutation } from '@apollo/client'
import { loader } from 'graphql.macro'
import {
  ValidInvitationCodeByUserTypeMutation,
  ValidInvitationCodeByUserTypeMutationVariables
} from '__generated__/typescript-operations'

export const VALID_INVITATION_CODE_KROWDER = loader('./validInvitationCodeByUserType.graphql')

export const useValidInvitationCodeByUserType = (
  params?: MutationHookOptions<ValidInvitationCodeByUserTypeMutation, ValidInvitationCodeByUserTypeMutationVariables>
) => useMutation(VALID_INVITATION_CODE_KROWDER, params as OperationVariables)
