import { DatePickerView } from '@ghondar/pickers/DatePicker/DatePicker'
import { setTime } from '@krowdy/utils'
import dayjs from 'dayjs'

export const getDays = (dateSelected: any) => {
  const currentDay = dayjs(dateSelected).get('days')

  return Array.from({ length: 4 }, (v, i) => {
    const today = dayjs()

    return {
      active: dateSelected && currentDay ? (currentDay - today.get('days')) === i : i === 0,
      day   : today.set('date', today.get('date') + i).set('minute', 0).set('second', 0)
    }
  })
}

export const getDaysWithoutWeekends = (dateSelected: any) => {
  const currentDay = dayjs(dateSelected).get('day')
  let arrayDays = []

  for (let i = 0; arrayDays.length < 4; i++) {
    const today = dayjs().add(i, 'day')
    // Exluir flujo cuando day fin de semana -> Domingo 0, Sabado => 6
    if(today.get('day') !== 0 && today.get('day') !== 6) {
      let dataToReturn = {
        active: dateSelected && currentDay ? (currentDay - today.get('day')) === i : i === 0,
        day   : today.set('minute', 0).set('second', 0)
      }
      arrayDays.push(dataToReturn)
    }
  }

  return arrayDays
}

export const intToHour = (number: number) => {
  let date = new Date(number * 3600 * 1000)

  return ('0' + date.getUTCHours()).slice(-2) + ':' +
    ('0' + date.getUTCMinutes()).slice(-2)
}

export const getHours = (dateSelected?: any, daySelect?: number) => {
  let hours = []
  const today = dayjs()
  const currentHour = dayjs(String(dateSelected)).get('hours')
  // const currentDay = dayjs(dateSelected).get('days')
  for (let i = 0; i < 24; i++)
    hours.push({
      active: dateSelected && currentHour ? currentHour === i : false,
      block : daySelect === today.get('days')  ? i <= today.get('hours') + 3 : false,
      hour  : intToHour(i)
    })

  return hours
}

export const getTimes = (fromTime:number): {[key: string]: number} => {
  let date = Math.abs(fromTime - new Date().getTime()) / 1000

  let result:{[key: string]: number} = {}

  const seconds:{[key: string]: number} = {
    day   : 86400,
    hour  : 3600,
    minute: 60
  }

  Object.keys(seconds).forEach((key) => {
    result[key] = Math.floor(date / seconds[key])
    date -= result[key] * seconds[key]
  })

  return result
}

export const formatTaskDate = (taskDate: number) => {
  if(!taskDate) return 'Por programar'

  const result = getTimes(taskDate)
  const { day, hour, minute } = result

  if(day > 30) return `En ${day} mes${day > 60 ? 'es' : ''}`

  if(day > 7) {
    const weeks = Math.floor(day / 7)

    return `En ${weeks} semana${weeks > 1 ? 's' : ''}`
  }

  if(day) return `En ${day} día${day > 1 ? 's' : ''}`

  if(hour) return `En ${hour} hora${hour > 1 ? 's' : ''}`

  if(minute > 10) return `En ${minute} minutos`

  return 'Por empezar'
}

export const isSameDayAndMonth = (date1?: string | number | Date | null, date2?: string | number | Date | null) => {
  if(!date1 && !date2) return true
  const firstDate = new Date(date1!)
  const secondDate = new Date(date2!)

  return firstDate.getDate() === secondDate.getDate() && firstDate.getMonth() === secondDate.getMonth()
}

export const getDateFieldConfig = (rangeFormatDate: string = 'MM/YYYY') => {
  const format = rangeFormatDate
  const mask = rangeFormatDate.replaceAll('M', '_').replaceAll('D', '_').replaceAll('Y', '_')
  const placeholder = rangeFormatDate.replaceAll('Y', 'A').toLowerCase()
  const views = rangeFormatDate
    .split('/')
    .map((section) => {
      if(section.includes('D')) return 'date'
      if(section.includes('M')) return 'month'
      if(section.includes('Y')) return 'year'

      return null
    })
    .filter(Boolean) as DatePickerView[]

  return {
    format,
    mask,
    placeholder,
    views
  }
}

export function dateIsValid(numero: number, intervalo: number[] = [ 9, 18 ], zonaHoraria = 'America/Lima') {
  // Obtener la hora actual en la zona horaria especificada
  // Convertir los límites del intervalo al huso horario especificado
  const [ limiteInferior, limiteSuperior ] = intervalo.map(hora => {
    const data = setTime({
      timezone: zonaHoraria,
      value   : hora
    })

    return data.getHours()
  })

  return numero >= limiteInferior && numero <= limiteSuperior
}
