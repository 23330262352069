import { makeVar } from '@apollo/client'
import { Maybe } from '__generated__/typescript-operations'

const currentStageIdVar = makeVar<Maybe<string>>(null)
const currentTaskIdVar = makeVar<Maybe<string>>(null)
const isCongratulationViewVar = makeVar<Maybe<boolean>>(false)
const initializePostulantStateVar = makeVar<Maybe<boolean>>(false)
const mountAppVar = makeVar<Maybe<boolean>>(false)

const State = {
  currentStageIdVar,
  currentTaskIdVar,
  initializePostulantStateVar,
  isCongratulationViewVar,
  mountAppVar
}

export default State
