import urlParser from 'url-parse'
import qs from 'query-string'
import { generatePath, matchPath } from 'react-router'
import { getCredentials } from './credentials'
import { getSearchParams } from './getQueryParams'

const {
  REACT_APP_BOLSAS_UNIVERSITARIAS_HOST,
  REACT_APP_LABORUM_SITE
} = process.env

export const krowdyGeneratePath = (path: string, taskId?: string): string => {
  const parseUrl = urlParser(path)

  const url = `${parseUrl.origin}${parseUrl.pathname}`

  const candidateMatch = matchPath<{jobId: string; candidateId: string;}>(window.location.pathname, {
    exact: true,
    path : '/:type/:jobId/publication/:publicationIndex/candidate/:candidateId'
  })

  if(candidateMatch) {
    const qsParams = getSearchParams()

    const alloweditpostulation = qs.stringify({
      editPostulationId: candidateMatch?.params.candidateId,
      userType         : qsParams?.userType ?? 'candidate'
    })

    return qs.stringifyUrl({
      query: {
        ...qs.parse(parseUrl.query),
        alloweditpostulation
      },
      url
    })
  }

  const { accessToken, iduser, refreshToken } = getCredentials()

  const query = {
    accessToken,
    iduser,
    refreshToken,
    serviceApp: 'applying',
    taskId
  }

  const urlRedirectObject = {
    query: qs.parse(parseUrl.query as any),
    url
  }

  const urlRedirect = qs.stringifyUrl(urlRedirectObject)

  return parseUrl ? qs.stringifyUrl({
    query: {
      ...query,
      // timestamp   : new Date().getTime(),
      urlRedirect
    },
    url: `${parseUrl.origin}/callback`
  })  : ''
}

interface GetPortalUrlRedirectArgs {
  jobId: string;
  title: string;
  slug: string;
  companyName?: string;
  q?: string;
}

interface GetPortalesJobURLArgs {
  jobId: string;
  title: string;
  slug: string;
  companyName?: string;
  q?: string;
}

export const getPortalUrlRedirect = ({
  jobId,
  title,
  slug,
  companyName,
  q
}: GetPortalUrlRedirectArgs): string => {
  const newSearch = qs.stringify({
    q: q ?? title
  })

  let pathBase = REACT_APP_LABORUM_SITE!

  if(slug !== 'laborum') pathBase = `https://${slug}.${REACT_APP_BOLSAS_UNIVERSITARIAS_HOST}`

  const pathname = generatePath('/job/:companyName/:jobTitle/:id', {
    companyName: companyName ?? 'Confidential',
    id         : jobId,
    jobTitle   : title
  })

  return krowdyGeneratePath(`${pathBase}${pathname}?${newSearch}`)
}

interface GetPortalUrlRedirectPostulationsArgs {
  slug: string;
}

export const getPortalUrlRedirectPostulations = ({ slug }: GetPortalUrlRedirectPostulationsArgs): string => {
  let pathBase = REACT_APP_LABORUM_SITE!

  if((slug) !== 'laborum') pathBase = `https://${slug}.${REACT_APP_BOLSAS_UNIVERSITARIAS_HOST}`

  const pathname = '/postulations'

  return krowdyGeneratePath(`${pathBase}${pathname}`)
}

export const getPortalesJobURL = ({
  jobId,
  title,
  slug,
  companyName,
  q
}: GetPortalesJobURLArgs): string => {
  const newSearch = qs.stringify({
    q: q ?? title
  })

  let pathBase = REACT_APP_LABORUM_SITE!

  if(slug !== 'laborum') pathBase = `https://${slug}.${REACT_APP_BOLSAS_UNIVERSITARIAS_HOST}`

  const pathname = generatePath('/job/:companyName/:jobTitle/:id', {
    companyName: companyName ?? 'Confidential',
    id         : jobId,
    jobTitle   : title
  })

  return `${pathBase}${pathname}?${newSearch}`
}

export const mongoObjectId = (): string => {
  const timestamp = (new Date().getTime() / 1000 | 0).toString(16)

  return timestamp + 'xxxxxxxxxxxxxxxx'.replace(/[x]/g, () => (Math.random() * 16 | 0).toString(16)).toLowerCase()
}
