import { ApolloClient, ApolloLink } from '@apollo/client'
import { BatchHttpLink } from '@apollo/client/link/batch-http'
import { disableFragmentWarnings } from 'graphql-tag'
import { onError } from '@apollo/client/link/error'
import { loader } from 'graphql.macro'
import qs from 'query-string'
import jstz from 'jstz'
import { matchPath } from 'react-router'

import { LOCAL_STORAGE_KEYS } from 'utils/constants'
import { cache } from './cache'
import { getSearchParams } from 'utils/getQueryParams'
import { isCandidatePublicView } from 'utils/flows'
import { validateLocalStorageAvailability } from 'utils/localstorage'
import { Maybe } from '@krowdy/kds-core'

const schema = loader('./schema.graphql')

disableFragmentWarnings()

const { REACT_APP_SERVER_URL, NODE_ENV } = process.env

const timezone = jstz.determine()

const buildUrlUri = (data : any) => {
  const keys = Object.keys(data)

  return keys.map((key) => `${key}=${data[key]}`).join('&')
}

const redirectToAuthWhenTokenIsInvalid = (message: string) => {
  if(!message.includes('Forbidden')) return

  const queryStringParams = getSearchParams()
  if(queryStringParams.accessToken) delete queryStringParams.accessToken

  const newSearch = qs.stringify(queryStringParams)
  const newHref = `${window.location.origin}${window.location.pathname}?${newSearch}`

  const match = matchPath<{jobId: string; publicationIndex: string;}>(window.location.pathname, {
    exact: true,
    path : '/:type/:jobId/publication/:publicationIndex'
  })

  let redirectUrl = {
    jobId      : match?.params?.jobId,
    timestamp  : new Date().getTime(),
    urlCallback: window.location.origin + '/callback',
    urlRedirect: newHref
  }

  const urlRedirectInvalidate = `${process.env.REACT_APP_ACCOUNTS_FRONT_URL || ''}/login?${buildUrlUri(redirectUrl)}`

  window.location.href = urlRedirectInvalidate
}

const validToken = (token: string) => {
  if(typeof token !== 'string') throw new Error('Forbidden: token is not valid')
  if(token === '[object Object]') throw new Error('Forbidden: token is not valid')
}

let ip: Maybe<string>

export const createClient = ({ headers }: { headers?: { authorization?: string; refreshToken?: string; }} = {}) => {
  const customFetch = async (uri: string, options: any) => {
    ip = ip ?? await fetch('https://checkip.amazonaws.com/').then(res => res.text())

    if(headers) {
      const { authorization = '', refreshToken } = headers

      validToken(authorization)

      options.headers.authorization = authorization ?  `Bearer ${authorization}` : ''
      options.headers.refreshToken = refreshToken
      options.headers.timezone = timezone.name()

      options.headers['x-forwarded-for'] = ip

      return fetch(uri, options)
    }

    const accessToken = window.localStorage.getItem(LOCAL_STORAGE_KEYS.ACCESS_TOKEN) || localStorage.getItem(LOCAL_STORAGE_KEYS.ACCESS_TOKEN) || ''

    const refreshToken = window.localStorage.getItem(LOCAL_STORAGE_KEYS.REFRESH_TOKEN) ||
      localStorage.getItem(LOCAL_STORAGE_KEYS.REFRESH_TOKEN) || ''

    validToken(accessToken)

    options.headers.authorization = accessToken ? `Bearer ${accessToken}` : ''
    options.headers.refreshToken = refreshToken
    options.headers.timezone = timezone.name()
    options.headers['x-forwarded-for'] = ip

    const qsParams = getSearchParams()

    // pensando q nunca se va a entrar a video entrevista, por eso solo se valida el path

    if(isCandidatePublicView()) {
      const match = matchPath<{jobId: string; candidateId: string;}>(window.location.pathname, {
        exact: true,
        path : '/:type/:jobId/publication/:publicationIndex/candidate/:candidateId'
      })

      options.headers.alloweditpostulation = qs.stringify({
        editPostulationId: match?.params.candidateId,
        userType         : qsParams?.userType ?? 'candidate'
      })
    }

    if(qsParams?.alloweditpostulation)
      options.headers.alloweditpostulation = qsParams?.alloweditpostulation

    return fetch(uri, options)
  }

  const errorHandler = onError(({ graphQLErrors, networkError }) => {
    // eslint-disable-next-line no-restricted-syntax
    console.error('graphQLErrors', JSON.stringify(graphQLErrors, null, 2))
    // eslint-disable-next-line no-restricted-syntax
    console.error('headers', headers)
    if(graphQLErrors && !headers)
      graphQLErrors.forEach(({ message }) => {
        redirectToAuthWhenTokenIsInvalid(message)
      })

    const isLocalStorageAvailable = validateLocalStorageAvailability()

    if(!isLocalStorageAvailable)
      window.location.href = `${process.env.REACT_APP_BASE_FRONT_URL || ''}/storage?source=${window.location.href}`

    if(networkError)
      // eslint-disable-next-line no-restricted-syntax
      console.log(`[Network error]: ${networkError}`)
  })

  const batchLink = new BatchHttpLink({
    batchInterval: 50,
    fetch        : customFetch,
    uri          : `${REACT_APP_SERVER_URL}/graphql`
  })

  const links = ApolloLink.from([ errorHandler, batchLink  ])

  const client = new ApolloClient({
    cache,
    link    : links,
    typeDefs: schema
    // uri     : `${process.env.REACT_APP_SERVER_URL}`
  })

  return client
}

const client = createClient()

declare global {
  interface Window { apolloClient: any; }
}

if(NODE_ENV === 'development')
  window.apolloClient = client

export default client
