import ReactDOM from 'react-dom'
import { SnackbarProvider } from 'notistack'
import * as Sentry from '@sentry/react'
import { AuthContext } from '@krowdy/kds-auth'
import Root from './routes'
import { ApolloProvider } from '@apollo/client'
import client from 'config/client'

import reportWebVitals from './reportWebVitals'

import './index.css'

let sentryDSN = process.env?.REACT_APP_SENTRY_DSN_KEY
let sentryAvailable = process.env?.REACT_APP_REACT_APP_SENTRY_MODE

if(sentryDSN && sentryAvailable === 'recording')
  Sentry.init({
    dsn                     : sentryDSN,
    integrations            : [ new Sentry.BrowserTracing(), new Sentry.Replay() ],
    replaysOnErrorSampleRate: 1.0,
    replaysSessionSampleRate: 0.1,
    tracesSampleRate        : 1.0
  })

ReactDOM.render(
  <ApolloProvider client={client}>
    <SnackbarProvider
      anchorOrigin={{
        horizontal: 'center',
        vertical  : 'top'
      }}>
      <AuthContext
        baseUrl={process.env.REACT_APP_ACCOUNTS_API_URL!}
        clientId={process.env.REACT_APP_CLIENT_ID!}
        clientSecret={process.env.REACT_APP_CLIENT_SECRET!}
        // eslint-disable-next-line no-restricted-syntax
        onError={(error) => console.error(error)}
        urlLogin={process.env.REACT_APP_ACCOUNTS_FRONT_URL!}>
        <Root />
      </AuthContext>
    </SnackbarProvider>
  </ApolloProvider>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
